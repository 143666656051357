import { useLanguage } from "@/components/context/LanguageContext";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import LoadingIcon from "@/components/loading/loading-icon";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import LogFeed from "@/components/log-feed";
import {
  project_statuses,
  projects,
  comments,
} from "@/services/project-service";
import { getSites } from "@/services/site-services";
import { useQuery } from "@tanstack/react-query";
import { MultiSelect } from "@/components/ui/multi-select";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useDropzone } from "react-dropzone";
import { TiDeleteOutline } from "react-icons/ti";
import { format, toZonedTime } from 'date-fns-tz';

const ManageProject = () => {
  const { id } = useParams<{ id: string }>();
  const [fetchedProject, setFetchProject] = useState<any>();
  const [updatedProject, setUpdatedProject] = useState<any>(null);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [projectManagers, setProjectManagers] = useState<any>();
  const [projectStatus, setProjectStatus] = useState<any>();
  const [sites, setSites] = useState<any>();
  // const [projectLogs, setProjectLogs] = useState<any>();
  const [projectSupportTeams, setProjectSupportTeams] = useState<any>();
  const [fetchOptions, setFetchOptions] = useState<any>(false);
  const [projectComments, setProjectComments] = useState<any>({
    comment: "",
    project: id,
    attachments: null,
  });
  const [projectCommentsError, setProjectCommentsError] = useState<any>("");
  const fetchData = async () => {
    await fetchProjectData();
    await fetchProjectSupportTeams();
    return true;
  };
  const [isCommentsSuccess, setIsCommentsSuccess] = useState(false);

  useEffect(() => {
    document.title = "Lazem GO - Manage Project";
  } , []);

  const {
    isLoading: projectsLoading,
    isFetching: projectsFetching,
  } = useQuery({
    queryKey: ["manage_project"],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
  });

  const fetchProjectData = async () => {
    await projects
      .get(id)
      .then((response:any) => {
        setFetchProject(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const fetchProjectManagers = async () => {
    setFetchOptions(true);
    await projects.list_project_managers()
      .then((response: any) => {
        setProjectManagers(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
    setFetchOptions(false);
  };

  const fetchSites = async () => {
    setFetchOptions(true);
    await getSites()
      .then((response:any) => {
        setSites(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
    setFetchOptions(false);
  };

  const fetchProjectStatus = async () => {
    setFetchOptions(true);
    await project_statuses.list()
      .then((response: any) => {
        setProjectStatus(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
    setFetchOptions(false);
  };

  // const fetchProjectLogs = async () => {
  //    setFetchOptions(true);
  //     // Fetch project logs from API
  // }

  const fetchProjectSupportTeams = async () => {
    setFetchOptions(true);
    await projects.list_project_support_teams()
      .then((response:any) => {
        let list: any = [];
        response.data.map((team: any) => {
          list.push({
            value: team.id,
            id: team.id,
            label: team.full_name,
          });
        });
        setProjectSupportTeams(list);
      })
      .catch((error) => {
        console.log("Error", error);
      });
    setFetchOptions(false);
  };
  const updateProjectData = async (field:any, value:any) => {
    setFetchProject({ ...fetchedProject, [field]: value });
    setUpdatedProject({ ...updatedProject, [field]: value });
  };

  const setInputField = (
    label: string,
    field: string,
    value: any,
    type?: string,
    canEdit?: boolean
  ) => {
    return (
      <div className="flex flex-col my-4">
        <Label className="mb-1">{label}</Label>
        <Input
          type={type ? type : "text"}
          value={value}
          onChange={(e) => {
            updateProjectData(field,e.target.value);
          }}
          disabled={canEdit || !isEditing}
        />
      </div>
    );
  };

  const setSelectField = (
    label: string,
    field: string,
    options: any,
    option_var_name: any,
    placeholder_var_name: any
  ) => {
    if (fetchedProject != null) {

      if (
        fetchedProject[field] != null &&
        placeholder_var_name in fetchedProject[field]
      ) {
        return (
          <div className="flex flex-col my-4">
            <Label className="mb-1">{label}</Label>
            <Select
              onValueChange={(value: any) => {
                setUpdatedProject({ ...updatedProject, [field]: value });
              }}
              disabled={!isEditing || fetchOptions}
              onOpenChange={(isOpen) => {
                if (isOpen) {
                  runFunction(field);
                }
              }}
            >
              <SelectTrigger isFetch={fetchOptions}>
                <SelectValue
                  placeholder={
                    !fetchedProject[field] ||
                      fetchedProject[field] === null ||
                      fetchedProject[field][placeholder_var_name]
                      ? fetchedProject[field][placeholder_var_name]
                      : language === "en"
                        ? "Select"
                        : "اختر"
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {options?.map((option: any) => (
                    <SelectItem key={option.id} value={option.id}>
                      {option[option_var_name]}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        );
      }
    }
    else {
      // Error should be raised here as a notification
      return <div> Error </div>;
    }
  };

  const setMultiSelectField = (label: string, field: string, options: any) => {
    let defaultOptions: any = [];
    if (fetchedProject != null) {

      fetchedProject[field].map((team: any) => {
        defaultOptions.push(team.id);
      });
      return (
        <div className="flex flex-col my-4">
          <Label className="mb-1">{label}</Label>
          <MultiSelect
            options={options}
            defaultValue={defaultOptions ? defaultOptions : []}
            onValueChange={(value: any) => {
              setFetchProject({ ...fetchedProject, [field]: value });
              setUpdatedProject({ ...updatedProject, [field]: value });
            }}
            disabled={!isEditing}
          />
        </div>
      );
    }
    else {
      // Error should be raised here as a notification
      return <div> Error </div>;
    }
  };

  const runFunction = (func: any) => {
    if (func === "project_manager") {
      if (!projectManagers) {
        fetchProjectManagers();
      }
      return;
    } else if (func === "status") {
      if (!projectStatus) {
        fetchProjectStatus();
      }
      return;
    } else if (func === "support_team") {
      if (!projectSupportTeams) {
        fetchProjectSupportTeams();
      }
      return;
    } else if (func === "site") {
      if (!sites) {
        fetchSites();
      }
      return;
    }
  };

  const convertDate = (date: any) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Riyadh';
    let formattedDateTime = format(toZonedTime(date, timeZone), 'MMMM d, yyyy hh:mm a', { timeZone });
    return formattedDateTime;
  }
  
  const setTaskField = (task: any) => {
    return (
      <Card
        key={task.id}
        className="mb-4 w-[300px]"
        // onClick={() => navigate(`/tasks/${task.id}/manage`)}
      >
        <CardHeader>
          <CardTitle>{task.title}</CardTitle>
          <CardDescription>{task.description}</CardDescription>
        </CardHeader>
        <CardContent>
          <p>
            {language === "en" ? "Start Date" : "تاريخ البدء"}
            <br />
            {convertDate(task?.start_date)}
          </p>
          <p>
            {language === "en" ? "End Date" : "تاريخ الانتهاء"}:
            <br />
            {convertDate(task?.end_date)}
          </p>
          <p
            className={` mt-2  p-1 border-t-2
              ${ task?.status === "completed"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {language === "en" ? "Status" : "الحالة"}: {task?.status}
          </p>
        </CardContent>
      </Card>
    );
  };

  const handelUpdateProject = async () => {
    if (!checkIfChanges()) {
      return;
    }
    setFormLoading(true);
    await projects
      .update(id, updatedProject)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setFormError(error.message);
        throw error;
      })
      .finally(() => {
        setFormLoading(false);
      });
  };

  const checkIfChanges = () => {
    if (updatedProject === null) {
      setFormError(
        language === "en" ? "No changes made" : "لم يتم إجراء أي تغيير"
      );
      return false;
    }
    setFormError("");
    return true;
  };

  const onDrop = (acceptedFiles: any) => {
    setProjectComments((prevState: any) => ({
      ...prevState,
      attachments: [...(prevState.attachments || []), ...acceptedFiles],
    }));
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handelCreateComment = async () => {
    if (projectComments.comment === "") {
      setProjectCommentsError(
        language === "en" ? "Comment is required" : "التعليق مطلوب"
      );
      return;
    }
    setProjectCommentsError("");
    setFormLoading(true);
    let formData = new FormData();
    formData.append("comment", projectComments.comment);
    formData.append("project", projectComments.project);
    if (projectComments.attachments) {
      for (let i = 0; i < projectComments.attachments.length; i++) {
        const file = projectComments.attachments[i];
        formData.append("attachments", file, file.name);
      }
    }
    await comments
      .create(formData)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        setFormLoading(false);
        setProjectCommentsError(error.message);
        console.log(error);
        throw error;
      });
  };

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {(projectsLoading || projectsFetching) &&  (
        <LoadingScreen />
      )}
      {
        <>
          {(fetchedProject || projectsFetching) && (
            <Card className="w-full lg:w-[800px]  m-auto">
              <CardHeader>
                <Button
                  onClick={() => navigate("/projects")}
                  variant={"outline"}
                  className="mb-4 flex"
                >
                  {language === "en" ? "Back" : "رجوع"}
                </Button>
                <CardTitle>
                  {language === "en" ? "Manage Project" : "إدارة المشروع"}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex justify-between items-center mb-4">
                  <Button
                    onClick={() => setIsEditing(!isEditing)}
                    className=""
                    variant={isEditing ? "destructive" : "outline"}
                    permission="change_projects"
                  >
                    {isEditing
                      ? language === "en"
                        ? "Cancel"
                        : "إلغاء"
                      : language === "en"
                        ? "Edit"
                        : "تعديل"}
                  </Button>
                  <Dialog>
                    <DialogTrigger>
                      <Button className="" variant="outline">
                        {language === "en" ? "Add Comment" : "إضافة تعليق"}
                      </Button>
                    </DialogTrigger>
                    <DialogContent
                      dir={language === "en" ? "ltr" : "rtl"}
                      className="text-start"
                    >
                      <DialogHeader>
                        <DialogTitle>
                          {language === "en" ? "Add Comment" : "إضافة تعليق"}
                        </DialogTitle>
                        {isCommentsSuccess && (
                          <p className="text-green-500 text-sm my-2">
                            {language === "en"
                              ? "Comment added successfully"
                              : "تمت إضافة التعليق بنجاح"}
                          </p>
                        )}
                      </DialogHeader>
                      <DialogDescription className="text-start">
                        {projectCommentsError && (
                          <p className="text-red-500 text-sm my-2">
                            {projectCommentsError}
                          </p>
                        )}
                        <div className="flex flex-col gap-4">
                          <Label className="!text-start flex">
                            {language === "en" ? "Comment" : "التعليق"}
                          </Label>
                          <Input
                            type="text"
                            value={projectComments.comment}
                            placeholder={
                              language === "en" ? "Comment" : "التعليق"
                            }
                            onChange={(e) => {
                              setProjectComments({
                                ...projectComments,
                                comment: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div>
                          {/*<!-- Component: Dropzone file input --> */}
                          <div className="relative my-6">
                            <div
                              {...getRootProps({
                                className:
                                  "relative flex cursor-pointer flex-col items-center gap-4 rounded border border-dashed border-slate-300 px-3 py-6 text-center text-sm font-medium transition-colors",
                              })}
                            >
                              <input {...getInputProps()} />
                              <IoCloudUploadOutline
                                className="inline-flex h-12 items-center justify-center self-center"
                                size={24}
                              />
                              <span className="text-slate-500">
                                {language === "en"
                                  ? "Drag and drop or Browse"
                                  : "اسحب أو تصفح"}
                              </span>
                            </div>
                          </div>
                          {projectComments.attachments && (
                            <div className="">
                              {projectComments.attachments.map((file: any) => (
                                <div className="flex gap-6 items-center justify-between my-2">
                                  <span key={file.path}>{file.path}</span>
                                  <TiDeleteOutline
                                    onClick={() => {
                                      let newFiles =
                                        projectComments.attachments.filter(
                                          (f: any) => f.path !== file.path
                                        );
                                      setProjectComments({
                                        ...projectComments,
                                        attachments: newFiles || null,
                                      });
                                    }}
                                    className="text-red-500 cursor-pointer"
                                    size={24}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                          {/*<!-- End Dropzone file input --> */}
                        </div>
                      </DialogDescription>
                      <DialogFooter className="flex gap-4 !justify-start">
                        <Button
                          onClick={handelCreateComment}
                          disabled={formLoading}
                        >
                          {formLoading ? (
                            <LoadingIcon />
                          ) : language === "en" ? (
                            "Add"
                          ) : (
                            "إضافة"
                          )}
                        </Button>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </div>
                <div className="flex flex-col gap-4 text-start">
                  <Card className="w-full p-2">
                    {setInputField(
                      language === "en" ? "Title" : "العنوان",
                      "title",
                      fetchedProject?.title
                    )}
                    {setInputField(
                      language === "en" ? "Description" : "الوصف",
                      "description",
                      fetchedProject?.description
                    )}
                    {setInputField(
                      language === "en" ? "Stage" : "المرحلة",
                      "stage",
                      fetchedProject?.stage?.title,
                      "text",
                      true
                    )}
                    {setInputField(
                      language === "en" ? "Type" : "النوع",
                      "type",
                      fetchedProject?.type?.title,
                      "text",
                      true
                    )}
                    {setInputField(
                      language === "en" ? "Start Date" : "تاريخ البدء",
                      "start_date",
                      fetchedProject?.start_date?.substring(0, 10),
                      "date"
                    )}
                    {setInputField(
                      language === "en" ? "End Date" : "تاريخ الانتهاء",
                      "end_date",
                      fetchedProject?.end_date?.substring(0, 10),
                      "date"
                    )}
                    {setSelectField(
                      language === "en" ? "Project Manager" : "مدير المشروع",
                      "project_manager",
                      projectManagers,
                      "full_name",
                      "full_name"
                    )}
                    {setSelectField(
                      language === "en" ? "Status" : "الحالة",
                      "status",
                      projectStatus,
                      "title",
                      "title"
                    )}
                    {setSelectField(
                      language === "en" ? "Site" : "الموقع",
                      "site",
                      sites,
                      "name",
                      "name"
                    )}
                    {setMultiSelectField(
                      language === "en" ? "Support Teams" : "فرق الدعم",
                      "support_team",
                      projectSupportTeams
                    )}
                    <CardFooter className="flex flex-col gap-4 w-full">
                      {formError && isEditing && (
                        <p className="text-red-500 text-sm">{formError}</p>
                      )}
                      {isEditing && (
                        <Button
                          onClick={handelUpdateProject}
                          disabled={formLoading || !isEditing}
                          className="w-full"
                        >
                          {formLoading && <LoadingIcon />}
                          {language === "en" ? "Update" : "تحديث"}
                        </Button>
                      )}
                    </CardFooter>
                  </Card>
                  <Card>
                    <CardTitle className="text-xl font-bold my-4 text-center">
                      {language === "en" ? "Tasks" : "المهام"}
                    </CardTitle>
                    <div className="flex flex-wrap gap-4 w-full justify-center align-items-center">
                      {fetchedProject?.tasks?.map((task: any) =>
                        setTaskField(task)
                      )}
                    </div>
                  </Card>
                  <Card>
                    <CardTitle className="text-xl font-bold my-4 text-center">
                      {language === "en" ? "Comments" : "التعليقات"}
                    </CardTitle>

                    <CardFooter>
                      <LogFeed logs={fetchedProject?.comments} />
                    </CardFooter>
                  </Card>
                </div>
              </CardContent>
            </Card>
          )}
        </>
      }
    </div>
  );
};

export default ManageProject;
