import ApiUrl from "./api-url";
import axios, { isCancel } from 'axios';
import { ApiHeaderOptions } from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";
import { useErrorStore } from "@/components/context/ErrorContext";

const shortener = {
    /*
    The short_code is the unique identifier for the short URL.
    To use the short code you can use the following URL (it's not working yet):
    https://link.lazem.sa/{short_code}

    These methods are accessable by users with permissions only.
    */
    list: async () => {
        let url = `${ApiUrl}/v1/shortener/shortener/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    get: async (short_code: any) => {
        const url = `${ApiUrl}/v1/shortener/shortener/${short_code}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    create: async (data: any) => {
        // const example = {
        //     "original_url": "https://lazem.sa", // Required
        //     "short_code": "lazem", // Unique and Required
        //     "is_active": true // Optional default is true and if false the short code will not be accessible by public
        // }
        const url = `${ApiUrl}/v1/shortener/shortener/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    update: async (short_code: any, data: any) => {
        // const example = {
        //     "original_url": "https://lazem.sa", 
        //     "short_code": "lazem", // Unique
        //     "is_active": true // If false the short code will not be accessible by public
        // }
        const url = `${ApiUrl}/v1/shortener/shortener/${short_code}/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    }
}

export { shortener };