import { useLanguage } from "@/components/context/LanguageContext"
import LoadingIcon from "@/components/loading/loading-icon"
import LoadingScreen from "@/components/loading/loading-screen"
import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { shortener } from "@/services/url-shortener-services"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { format, toZonedTime } from "date-fns-tz";

const ShortenerLinksManage = () => {
    const { language } = useLanguage()
    const navigate = useNavigate()
    const { shortCode } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [formError, setFormError] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [updatedFields, setUpdatedFields] = useState<any>({})

    const {
        data: shortenerLinkData,
        isLoading: shortenerLinkIsLoading,
    } = useQuery({
        queryKey: ['shortenerLink', shortCode],
        queryFn: () => shortener.get(shortCode).then((response:any) => response?.data),
        refetchOnWindowFocus: false,
    })

    const updateShortenerLink = async () => {
        if (checkIfFormIsFilled()) {
            return
        }
        setFormError('')
        setIsLoading(true)
        await shortener.update(shortCode, updatedFields).then((response:any) => {
            navigate('/shortener-links/short-code/' + response?.data?.short_code)
        })
        setIsLoading(false)
    }

    const checkIfFormIsFilled = () => {
        if (updatedFields?.original_url === '' || updatedFields?.short_code === '') {
            setFormError(language === 'en' ? 'Please fill all the fields' : 'الرجاء ملء جميع الحقول')
            return true
        }
        else if (Object.keys(updatedFields).length === 0) {
            setFormError(language === 'en' ? 'At least one field should be updated' : 'يجب تحديث حقل واحد على الأقل')
            return true
        }
        else if (updatedFields?.original_url && !updatedFields?.original_url?.startsWith('https://')) {
            setFormError(language === 'en' ? 'Please enter a valid URL' : 'الرجاء إدخال رابط صحيح')
            return true
        }
        else if (shortenerLinkData?.short_code === updatedFields?.short_code) {
            setFormError(language === 'en' ? 'Please enter a different short code' : 'الرجاء إدخال رمز مختصر مختلف')
            return true
        }
        else if (shortenerLinkData?.original_url === updatedFields?.original_url) {
            setFormError(language === 'en' ? 'Please enter a different original URL' : 'الرجاء إدخال رابط أصلي مختلف')
            return true
        }
        return false
    }
    

    function formatDate(isoDate: any) {
        const timeZone =
            Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Riyadh";
        let formattedDateTime = format(
            toZonedTime(isoDate, timeZone),
            "MMMM d, yyyy hh:mm a",
            { timeZone }
        );
        return formattedDateTime;
    }

    return (
        <div
            className="p-4"
            dir={language === 'en' ? 'ltr' : 'rtl'}
        >
            {shortenerLinkIsLoading && <LoadingScreen />}
            <Card
                className="text-start"
            >
                <CardHeader>
                    <Button
                        className="m-3 flex"
                        onClick={() => navigate('/shortener-links')}
                        variant={"secondary"}
                    >
                        {language === 'en' ? 'Back' : 'رجوع'}
                    </Button>
                    <CardTitle
                        className="text-center"
                    >
                        {language === 'en' ? 'Manage Shortener Link' : 'إدارة الرابط المختصر'}
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    {shortenerLinkData && (
                        <>
                            <Button
                                className="m-3 flex"
                                onClick={() => setIsEdit(!isEdit)}
                                variant={isEdit ? 'destructive' : 'default'}
                                permission="change_shortener"
                            >
                                {isEdit ? (language === 'en' ? 'Cancel' : 'إلغاء') : (language === 'en' ? 'Edit' : 'تعديل')}
                            </Button>
                            <div
                                className="w-full lg:w-1/2 mx-auto md:w-3/4"
                            >
                                <div
                                    className="grid grid-cols-1 md:grid-cols-2 gap-4 text-start mx-auto w-full my-6"
                                >
                                    <div>
                                        <Label>{language === 'en' ? 'Created At' : 'تاريخ الإنشاء'}:{" "}</Label>
                                        <Label>
                                            {formatDate(shortenerLinkData?.created_at)}
                                        </Label>
                                    </div>
                                    <div>
                                        <Label>{language === 'en' ? 'Created By' : 'تم الإنشاء بواسطة'}:{" "}</Label>
                                        <Label>{shortenerLinkData?.created_by}</Label>
                                    </div>
                                    <div>
                                        <Label>{language === 'en' ? 'Updated At' : 'تاريخ التحديث'}:{" "}</Label>
                                        <Label>
                                            {formatDate(shortenerLinkData?.updated_at)}
                                        </Label>
                                    </div>
                                    <div>
                                        <Label>{language === 'en' ? 'Updated By' : 'تم التحديث بواسطة'}:{" "}</Label>
                                        <Label>{shortenerLinkData?.updated_by}</Label>
                                    </div>
                                    <div>
                                        <Label>{language === 'en' ? 'Click Count' : 'عدد النقرات'}:{" "}</Label>
                                        <Label>{shortenerLinkData?.click_count}</Label>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="grid grid-cols-1 gap-2 mt-6"
                            >
                                <Label>{language === 'en' ? 'Original URL' : 'الرابط الأصلي'}:{" "}</Label>
                                <Input
                                    className="w-full"
                                    defaultValue={shortenerLinkData?.original_url}
                                    value={updatedFields?.original_url ? updatedFields?.original_url : shortenerLinkData?.original_url}
                                    onChange={(e) => setUpdatedFields({ ...updatedFields, original_url: e.target.value })}
                                    disabled={!isEdit}
                                />
                            </div>
                            <div
                                className="grid grid-cols-1 gap-2 mt-6"
                            >
                                <Label>{language === 'en' ? 'Short Code' : 'الرمز المختصر'}:{" "}</Label>
                                <Input
                                    className="w-full"
                                    defaultValue={shortenerLinkData?.short_code}
                                    value={updatedFields?.short_code ? updatedFields?.short_code : shortenerLinkData?.short_code}
                                    onChange={(e) =>
                                        setUpdatedFields({
                                            ...updatedFields,
                                            short_code: e.target.value.replace(/\s+/g, '-'),
                                        })
                                    }
                                    disabled={!isEdit}
                                />
                                <Label>
                                    https://link.lazem.sa/{updatedFields?.short_code ? updatedFields?.short_code.toLowerCase().replace(/ /g, '-') : shortenerLinkData?.short_code.toLowerCase().replace(/ /g, '-')}
                                </Label>

                            </div>

                            <div
                                className="grid grid-cols-1 mt-6"
                            >
                                <Label>{language === 'en' ? 'Is Active' : 'نشط'}:{" "}</Label>
                                <RadioGroup
                                    defaultValue={shortenerLinkData?.is_active ? 'true' : 'false'}
                                    onValueChange={(e) => setUpdatedFields({ ...updatedFields, is_active: e === 'true' ? true : false })}
                                    dir={language === 'en' ? 'ltr' : 'rtl'}
                                    disabled={!isEdit}
                                >
                                    <div
                                        className="flex items-center gap-2 mt-2"
                                    >
                                        <RadioGroupItem
                                            value={"true"}
                                            id="true"
                                        />
                                        <Label
                                            htmlFor="true"
                                        >
                                            {language === 'en' ? 'Yes' : 'نعم'}
                                        </Label>
                                    </div>
                                    <div
                                        className="flex items-center gap-2"
                                    >
                                        <RadioGroupItem
                                            value={"false"}
                                            id="false"
                                        />
                                        <Label
                                            htmlFor="false"
                                        >
                                            {language === 'en' ? 'No' : 'لا'}
                                        </Label>
                                    </div>
                                </RadioGroup>
                            </div>

                        </>
                    )}
                </CardContent>
                <CardFooter>
                    {formError && <div className="text-red-500">{formError}</div>}
                </CardFooter>
                <CardFooter>
                    {
                        isEdit &&
                        <Button
                            className="w-full"
                            onClick={() => updateShortenerLink()}
                            disabled={isLoading}
                            permission="change_shortener"
                        >
                            {isLoading && <LoadingIcon />}
                            {language === 'en' ? 'Update' : 'تحديث'}
                        </Button>
                    }
                </CardFooter>
            </Card>
        </div>
    )
}

export default ShortenerLinksManage