import { useLanguage } from "@/components/context/LanguageContext";
import { getMyProfile, staffCertificates, updateProfile, users } from "@/services/user-service";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "@/components/ui/card";
import LoadingIcon from "@/components/loading/loading-icon";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import LoadingScreen from "@/components/loading/loading-screen";
import { useQuery } from "@tanstack/react-query";
import { departments } from "@/services/project-service";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem,
} from "@/components/ui/select";
import { FiRefreshCw, FiTrash2 } from "react-icons/fi";
import { MultiSelect } from "@/components/ui/multi-select";
import { PermissionGroups } from "@/services/permission-group-services";
import { is_arabic, is_english } from "@/services/language-checker";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { Textarea } from "@/components/ui/textarea";
import { motion } from "framer-motion";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useTheme } from "@/components/theme-provider";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { FiDownload } from "react-icons/fi";

const EditUser = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState<any>();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [updatedFields, setUpdatedFields] = useState<any>();
  const [showCertificate, setShowCertificate] = useState(false);
  const [certificateInfo, setCertificateInfo] = useState<any>({
    file: [],
    title: "",
    description: "",
  });
  const [certificateError, setCertificateError] = useState("");
  const [isCertificateSuccess, setIsCertificateSuccess] = useState(false);
  const [enlargedFile, setEnlargedFile] = useState<any>(null);
  const [isEnlargedFile, setIsEnlargedFile] = useState(false);
  useEffect(() => {
    document.title = "Lazem GO - Edit User";
  }, []);

  const {
    isLoading: profileLoading,
    refetch: fetchProfile,
    isRefetching: isProfileFetching,
  } = useQuery({
    queryKey: ["profile"],
    queryFn: () => {
      return users
        .get(id)
        .then((response: any) => {

          setProfile(response.data);
        })
        .catch((error: any) => {
          console.log("Error", error);
        });
    },
    refetchOnWindowFocus: false,
  });

  const {
    data: departmentsData,
    isLoading: departmentsLoading,
    refetch: fetchDepartments,
    isRefetching: isDepartmentFetching,
  } = useQuery({
    queryKey: ["departments"],
    queryFn: () => {
      return departments.list().then((response: any) => {
        return response.data;
      });
    },
    refetchOnWindowFocus: false,
  });
  const { data: permission_groups, isLoading: isPermissionGroupsLoading } =
    useQuery({
      queryKey: ["permission_groups"],
      queryFn: async () => {
        return await PermissionGroups.list().then((response: any) => {
          return response;
        });
      },
      refetchOnWindowFocus: false,
    });

  const checkUpdatedFields = () => {
    if (!updatedFields || updatedFields === null) {
      setFormError(
        language === "en"
          ? "Please update at least one field"
          : "الرجاء تحديث حقل واحد على الأقل"
      );
      return false;
    }
    else if (
      updatedFields.first_name &&
      !is_english(updatedFields.first_name)
    ) {
      setFormError(
        language === "en"
          ? "English First Name should be in English"
          : "الإسم الأول بالإنجليزية يجب أن يكون بالإنجليزية"
      );
      return false;
    }
    else if (
      updatedFields.middle_name &&
      !is_english(updatedFields.middle_name)
    ) {
      setFormError(
        language === "en"
          ? "English Middle Name should be in English"
          : "الإسم الأوسط بالإنجليزية يجب أن يكون بالإنجليزية"
      );
      return false;
    }
    else if (
      updatedFields.last_name &&
      !is_english(updatedFields.last_name)
    ) {
      setFormError(
        language === "en"
          ? "English Last Name should be in English"
          : "الإسم الأخير بالإنجليزية يجب أن يكون بالإنجليزية"
      );
      return false;
    }
    else if (
      updatedFields.first_name_ar &&
      !is_arabic(updatedFields.first_name_ar)
    ) {
      setFormError(
        language === "en"
          ? "Arabic First Name should be in Arabic"
          : "الإسم الأول بالعربية يجب أن يكون بالعربية"
      );
      return false;
    }
    else if (
      updatedFields.middle_name_ar &&
      !is_arabic(updatedFields.middle_name_ar)
    ) {
      setFormError(
        language === "en"
          ? "Arabic Middle Name should be in Arabic"
          : "الإسم الأوسط بالعربية يجب أن يكون بالعربية"
      );
      return false;
    }
    else if (
      updatedFields.last_name_ar &&
      !is_arabic(updatedFields.last_name_ar)
    ) {
      setFormError(
        language === "en"
          ? "Arabic Last Name should be in Arabic"
          : "الإسم الأخير بالعربية يجب أن يكون بالعربية"
      );
      return false;
    }
    else if (
      updatedFields.first_name === "" ||
      updatedFields.last_name === ""
    ) {
      setFormError(
        language === "en"
          ? "English First and Last Name are required"
          : "الإسم الأول والأخير بالإنجليزية مطلوبين"
      );
      return false;
    } else if (
      updatedFields.email === "" ||
      (updatedFields.email && !updatedFields.email.endsWith("@lazem.sa"))
    ) {
      setFormError(
        language === "en"
          ? "Please enter a valid Lazem email"
          : "الرجاء إدخال بريد إلكتروني خاص بلازم"
      );
      return false;
    } else if (
      updatedFields.staff_profile &&
      updatedFields.staff_profile.department !== "" &&
      updatedFields.staff_profile.role === null
    ) {
      setFormError(
        language === "en"
          ? "Please select a role"
          : "الرجاء اختيار المسمى الوظيفي"
      );
      return false;
    }
    return true;
  };

  const checkCertificateFields = () => {
    if (!certificateInfo.title) {
      setCertificateError(
        language === "en"
          ? "Provide a title for the certificate"
          : "الرجاء تقديم عنوان للشهادة"
      );
      return false;
    }
    if (certificateInfo.file.length === 0) {
      setCertificateError(
        language === "en"
          ? "Please upload a certificate"
          : "الرجاء تحميل الشهادة"
      );
      return false;
    }
    return true;
  };

  const updateProfileData = async () => {
    if (checkUpdatedFields()) {
      setFormLoading(true);
      setFormError("");
      await users
        .update(id, updatedFields)
        .then((response: any) => {
          fetchProfile();
          setUpdatedFields(null);
          setFormLoading(false);
          setIsEditing(false);
        })
        .catch((error: any) => {
          console.log("Error", error);
          setFormLoading(false);
        });
    }
  };

  const fileViewer = (file: any) => {
    let fileType = file?.file?.split("?")[0].split(".").pop();
    if (fileType === "pdf") fileType = "application/pdf";
    console.log(file.title);
    if (fileType === "application/pdf") {
      return (
        <>
          <Card>
            {
              file?.is_active ?

                <Button
                  variant="destructive"
                  className="mt-2 ml-2 mr-auto flex"
                  onClick={() => {
                    staffCertificates.deactivate(file.id).then(() => {
                      fetchProfile();
                    });
                  }}
                >
                  <FiTrash2 />
                </Button>
                :
                <Button
                  variant="outline"
                  className="mt-2 ml-2 mr-auto flex"
                  onClick={() => {
                    staffCertificates.activate(file.id).then(() => {
                      fetchProfile();
                    });
                  }}
                >
                  {language === "en" ? "Activate" : "تفعيل"}
                </Button>
            }
            <CardHeader>
              <CardTitle
                className="text-xl font-bold text-center"
              >
                {file?.title}
              </CardTitle>
              <CardDescription
                className="text-center"
              >
                {file?.description}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Dialog
                open={isEnlargedFile && enlargedFile?.id === file.id}
                onOpenChange={(isOpen) => {
                  if (!isOpen) {
                    setIsEnlargedFile(false);
                  }
                }}
              >
                <DialogTrigger asChild>
                  <div
                    style={{ cursor: 'pointer', display: 'inline-block', width: '150px', height: '100px' }}
                    onClick={() => {
                      setEnlargedFile(file);
                      setIsEnlargedFile(true);
                    }}
                  >
                    <iframe
                      onClick={() => {
                        setEnlargedFile(file);
                        setIsEnlargedFile(true);
                      }}
                      src={file?.file}
                      width="100%"
                      height="100%"
                      style={{ border: "none" }}
                    ></iframe>
                  </div>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[80vw] h-[100vh] p-8">
                  {/* <div className="flex justify-end">
              <a href={file?.file} download={file?.title || "certificate.pdf"}>
                <Button variant="outline" size="icon">
                  <FiDownload className="h-4 w-4" />
                </Button>
              </a>
            </div> */}
                  <iframe
                    src={file?.file}
                    width="100%"
                    height="100%"

                    style={{ border: "none", borderRadius: "10px", height: "100%", minHeight: "80vh" }}
                  ></iframe>
                </DialogContent>
              </Dialog>

            </CardContent>
            <CardFooter
              className="flex flex-col"
            >
              <Button
                className="w-full"
                onClick={() => {
                  setEnlargedFile(file);
                  setIsEnlargedFile(true);
                }}
              >
                {language === "en" ? "View" : "عرض"}
              </Button>
            </CardFooter>
          </Card>


        </>
      );
    }
    return (
      <>
        <Card>
          {
            file?.is_active ?

              <Button
                variant="destructive"
                className="mt-2 ml-2 mr-auto flex"
                onClick={() => {
                  staffCertificates.deactivate(file.id).then(() => {
                    fetchProfile();
                  });
                }}
              >
                <FiTrash2 />
              </Button>
              :
              <Button
                variant="outline"
                className="mt-2 ml-2 mr-auto flex"
                onClick={() => {
                  staffCertificates.activate(file.id).then(() => {
                    fetchProfile();
                  });
                }}
              >
                {language === "en" ? "Activate" : "تفعيل"}
              </Button>
          }
          <CardHeader>
            <CardTitle
              className="text-xl font-bold text-center"
            >
              {file?.title}
            </CardTitle>
            <CardDescription
              className="text-center"
            >
              {file?.description}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <FileMosaic
              id={file?.id}
              type={fileType}
              name={file?.title}
              imageUrl={file?.file}
              darkMode={theme === "dark"}
              preview
              onClick={() => window.open(file?.file, "_blank")}
              className="transition-transform duration-200 hover:scale-105 mt-10"
            />


          </CardContent>
          <CardFooter
            className="flex flex-col"
          >
            <Button
              className="w-full"
              onClick={() => window.open(file?.file, "_blank")}
            >
              {language === "en" ? "View" : "عرض"}
            </Button>
          </CardFooter>
        </Card>
      </>
    );
  };

  const setMultiSelectField = (
    label: string,
    field: string,
    options: any,
    default_value: any,
    with_add?: boolean,
    url?: string
  ) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-1">{label}</Label>
        <MultiSelect
          defaultValue={default_value}
          options={options}
          onValueChange={(value: any) => {
            setUpdatedFields({ ...updatedFields, [field]: value });
          }}
          animation={2}
          className="w-full"
          disabled={!isEditing}
        />
        {with_add && (
          <Button
            variant="link"
            className="text-sm flex justify-start w-fit"
            onClick={() => window.open(url ? url : "", "_blank")}
          >
            {language === "en" ? "Add New" : "إضافة جديد"}
          </Button>
        )}
      </div>
    );
  };

  const setInputField = (
    label: string,
    field: string,
    value: any,
    type?: string,
    is_disabled?: boolean
  ) => {
    return (
      <div className="flex flex-col my-4 text-start">
        <Label className="mb-2">{label}</Label>
        <Input
          type={type ? type : "text"}
          value={value}
          disabled={is_disabled ? is_disabled : !isEditing}
          onChange={(e) => {
            const newValue = e.target.value;
            if (field === "phone_number" || field === "address") {
              setProfile((prev: any) => ({
                ...prev,
                profile_user: {
                  ...prev?.profile_user,
                  [field]: newValue,
                },
              }));
              setUpdatedFields((prev: any) => ({
                ...prev,
                profile_user: {
                  ...prev?.profile_user,
                  [field]: newValue,
                },
              }));
            }
            else if (field === "employee_id" || field === "landline" || field === "landline_extension") {
              setProfile((prev: any) => ({
                ...prev,
                staff_profile: {
                  ...prev?.staff_profile,
                  [field]: newValue,
                },
              }));
              setUpdatedFields((prev: any) => ({
                ...prev,
                staff_profile: {
                  ...prev?.staff_profile,
                  [field]: newValue,
                },
              }));
            }
            else {
              setProfile((prev: any) => ({
                ...prev,
                [field]: newValue,
              }));
              setUpdatedFields((prev: any) => ({
                ...prev,
                [field]: newValue,
              }));
            }
          }}
        />
      </div>
    );
  };

  const updateFiles = (incommingFiles: any) => {
    console.log(incommingFiles);
    if (incommingFiles.length === 0) {
      setCertificateInfo({
        ...certificateInfo,
        file: [],
      });
    }
    else if (incommingFiles[0].valid) {
      setCertificateInfo({
        ...certificateInfo,
        file: incommingFiles,
      });
    }
  };
  const removeFile = (id: any) => {
    setCertificateInfo(
      {
        ...certificateInfo,
        file: [],
      }
    );
  };

  const submitCertificates = async () => {
    if (!checkCertificateFields()) {
      return;
    }
    setFormLoading(true);
    setCertificateError("");
    let formData = new FormData();
    formData.append("file", certificateInfo.file[0].file);
    formData.append("title", certificateInfo.title);
    formData.append("description", certificateInfo.description);
    formData.append("staff_profile", profile?.staff_profile?.id);
    formData.append("is_active", "true");
    await staffCertificates
      .post(formData)
      .then((response: any) => {
        setCertificateInfo({
          file: [],
          title: "",
          description: "",
        });
        setFormLoading(false);
        setIsCertificateSuccess(true);
        fetchProfile();
        setTimeout(() => {
          setIsCertificateSuccess(false);
          setShowCertificate(false);
        }, 2000);
      }
      )
      .catch((error: any) => {
        console.log("Error", error);
        setFormLoading(false);
      }
      );
  };

  const setSelectField = (
    label: string,
    field: string,
    options: any,
    default_value: any,
    with_add?: boolean,
    url?: string
  ) => {
    if (!options) {
      return (
        <>
          <LoadingIcon />
        </>
      );
    } else {
      return (
        <div className="flex flex-col my-6">
          <Label className="mb-1">{label}</Label>
          <div className="flex gap-2">
            <div className={`${with_add ? "w-4/5 " : "w-full"}`}>
              <Select
                disabled={!isEditing}
                onValueChange={(value: any) => {
                  if (field === "department") {
                    setProfile((prev: any) => ({
                      ...prev,
                      staff_profile: {
                        ...prev?.staff_profile,
                        [field]: { id: value },
                        role: null,
                      },
                    }));
                    setUpdatedFields((prev: any) => ({
                      ...prev,
                      staff_profile: {
                        ...prev?.staff_profile,
                        [field]: value,
                        role: null,
                      },
                    }));
                  } else {
                    setProfile((prev: any) => ({
                      ...prev,
                      staff_profile: {
                        ...prev?.staff_profile,
                        [field]: value,
                      },
                    }));
                    setUpdatedFields((prev: any) => ({
                      ...prev,
                      staff_profile: {
                        ...prev?.staff_profile,
                        [field]: value,
                      },
                    }));
                  }
                }}
                defaultValue={default_value}
              >
                <SelectTrigger
                  isFetch={isDepartmentFetching || departmentsLoading}
                >
                  <SelectValue
                    placeholder={
                      language === "en" ? "Select" + label : "اختر" + label
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>
                      {language === "en" ? "Select" + label : "اختر" + label}
                    </SelectLabel>
                    {options?.map((option: any) => (
                      <SelectItem key={option.id} value={option.id}>
                        {option.title} {option.code ? `(${option.code})` : ""}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div>
              {with_add && (
                <Button
                  variant="outline"
                  onClick={() => functionToCall(field)}
                  className=""
                >
                  <FiRefreshCw />
                </Button>
              )}
            </div>
          </div>
          {with_add && (
            <Button
              variant="link"
              className="text-sm flex justify-start w-fit"
              onClick={() => window.open(url ? url : "", "_blank")}
            >
              {language === "en" ? "Add New" : "إضافة جديد"}
            </Button>
          )}
        </div>
      );
    }
  };

  const functionToCall = (field: string) => {
    if (field === "department") {
      fetchDepartments();
    }
  };
  const permissionGroupsSerializer = (data: any) => {
    if (!data) {
      return [];
    }
    return data.map((group: any) => {
      return {
        value: group.id,
        label: group.name,
      };
    });
  };
  const defaultValueSerializer = (data: any) => {
    if (!data) {
      return [];
    }
    return data.map((group: any) => {
      return group.id;
    });
  };

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {profileLoading || (isProfileFetching && <LoadingScreen />)}
      {profile && departments && (
        <>
          <Card className="mx-auto mt-8 max-w-2xl">
            <CardHeader>
              <Button
                onClick={() => navigate("/organization/users")}
                className="w-full my-4"
                variant={"secondary"}
                disabled={formLoading}
              >
                {language === "en" ? "Back" : "رجوع"}
              </Button>
              <CardTitle className="text-center font-semibold text-xl">
                {language === "en" ? "" : "ملف"} {profile.first_name}{" "}
                {profile.last_name} {language === "en" ? "Profile" : " الشخصي"}
              </CardTitle>

              <div className="flex justify-between items-center">
                <Button
                  onClick={() => setIsEditing(!isEditing)}
                  className="w-fit mt-6"
                  variant={isEditing ? "destructive" : "default"}
                  permission="change_user"
                  disabled={formLoading}
                >
                  {isEditing
                    ? language === "en"
                      ? "Cancel"
                      : "إلغاء"
                    : language === "en"
                      ? "Edit Profile"
                      : "تعديل الملف الشخصي"}
                </Button>
                <Button
                  onClick={() => setShowCertificate(!showCertificate)}
                  variant={showCertificate ? "destructive" : "default"}
                  className="w-fit mt-6"
                  disabled={formLoading}
                >
                  {showCertificate
                    ? language === "en"
                      ? "Hide Certificate"
                      : "إخفاء الشهادة"
                    : language === "en"
                      ? "Add Certificate"
                      : "إضافة شهادة"}
                </Button>
              </div>

            </CardHeader>
            <CardContent>
              {/* Certificates Section - Initially Hidden */}
              <div className="flex flex-col">
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: showCertificate ? "auto" : 0, opacity: showCertificate ? 1 : 0 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="overflow-hidden text-start"
                >
                  {isCertificateSuccess ? (
                    <div className="text-green-500 font-semibold w-fit mx-auto flex items-center gap-2 flex-col">
                      {language === "en"
                        ? "Certificate added successfully"
                        : "تمت إضافة الشهادة بنجاح"}
                      <IoIosCheckmarkCircleOutline
                        size={40}
                      />

                    </div>
                  )
                    :
                    <div className="p-4 border rounded-lg mt-4 gap-4 flex flex-col">
                      <div>
                        <Label className="mb-1">
                          {language === "en" ? "Certificate Title" : "عنوان الشهادة"}
                        </Label>
                        <Input
                          type="text"
                          value={certificateInfo.title}
                          onChange={(e) =>
                            setCertificateInfo({ ...certificateInfo, title: e.target.value })
                          }
                        />
                      </div>
                      <div>
                        <Label className="mb-1 mt-4">
                          {language === "en" ? "Certificate Description" : "وصف الشهادة"}
                        </Label>
                        <Textarea
                          value={certificateInfo.description}
                          onChange={(e) =>
                            setCertificateInfo({ ...certificateInfo, description: e.target.value })
                          }
                        />
                      </div>

                      <div>
                        <Label className="mb-1 mt-4">
                          {language === "en" ? "Upload Certificate" : "تحميل الشهادة"}
                        </Label>
                        <Dropzone
                          onChange={updateFiles}
                          multiple={false}
                          value={certificateInfo.file}
                          maxFiles={1}
                          accept="image/* , application/pdf"
                          footerConfig={{
                            customMessage:
                              language === "en" ? "Only images are allowed" : "الصور فقط مسموح بها",
                          }}
                        >
                          {certificateInfo.file?.length !== 0 ? (
                            certificateInfo.file.map((file: any) => (
                              <FileMosaic
                                preview={true}
                                key={file.id}
                                {...file}
                                onDelete={removeFile}
                                info
                              />
                            ))
                          ) : (
                            <div className="text-gray-400 text-sm">
                              {language === "en"
                                ? "Drag and drop or click to upload"
                                : "اسحب وأسقط أو انقر للتحميل"}
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      {certificateError && (
                        <div className="text-red-500 font-semibold ">
                          {certificateError}
                        </div>
                      )}
                      <Button
                        onClick={() => submitCertificates()}
                        className="mt-4 w-full"
                      >
                        {formLoading ? (
                          <LoadingIcon />
                        ) : language === "en" ? (
                          "Add Certificate"
                        ) : (
                          "إضافة شهادة"
                        )}
                      </Button>
                    </div>
                  }
                </motion.div>
              </div>
              <div className="text-start space-y-4 flex flex-col">
                {setInputField(
                  language === "en" ? "First Name (English)" : "الإسم الأول (الإنجليزية)",
                  "first_name",
                  profile?.first_name
                )}
                {setInputField(
                  language === "en" ? "Middle Name (English)" : "الإسم الأوسط (الإنجليزية)",
                  "middle_name",
                  profile?.middle_name
                )}
                {setInputField(
                  language === "en" ? "Last Name (English)" : "الإسم الأخير (الإنجليزية)",
                  "last_name",
                  profile?.last_name
                )}
                {setInputField(
                  language === "en" ? "First Name (Arabic)" : "الإسم الأول (العربية)",
                  "first_name_ar",
                  profile?.first_name_ar
                )}
                {setInputField(
                  language === "en" ? "Middle Name (Arabic)" : "الإسم الأوسط (العربية)",
                  "middle_name_ar",
                  profile?.middle_name_ar
                )}
                {setInputField(
                  language === "en" ? "Last Name (Arabic)" : "الإسم الأخير (العربية)",
                  "last_name_ar",
                  profile?.last_name_ar
                )}
                {setInputField(
                  language === "en" ? "Email" : "البريد الإلكتروني",
                  "email",
                  profile.email,
                  'email',
                  true
                )}
                {setInputField(
                  language === "en" ? "Phone" : "الهاتف",
                  "phone_number",
                  profile?.profile_user?.phone_number
                )}
                <div
                  className="flex gap-4"
                >
                  <div
                    className="w-4/5"
                  >
                    {
                      setInputField(
                        language === "en" ? "Landline" : "الهاتف الأرضي",
                        "landline",
                        profile?.staff_profile?.landline
                      )
                    }
                  </div>
                  <div>
                    {
                      setInputField(
                        language === "en" ? "Extension" : "التحويلة",
                        "landline_extension",
                        profile?.staff_profile?.landline_extension
                      )
                    }
                  </div>
                </div>
                {setInputField(
                  language === "en" ? "Address" : "العنوان",
                  "address",
                  profile?.profile_user?.address
                )}
                {setInputField(
                  language === "en" ? "Employee ID" : "الرقم الوظيفي",
                  "employee_id",
                  profile?.staff_profile?.employee_id
                )
                }
                {isDepartmentFetching || departmentsLoading ? (
                  <LoadingIcon />
                ) : (
                  <>
                    {setSelectField(
                      language === "en" ? "Department" : "القسم",
                      "department",
                      departmentsData,
                      profile?.staff_profile?.department?.id,
                      true,
                      "/organization/create-department"
                    )}

                    {profile?.staff_profile?.department?.id &&
                      departmentsData &&
                      setSelectField(
                        language === "en" ? "Role" : "المسمى الوظيفي",
                        "role",
                        departmentsData.find(
                          (department: any) =>
                            department.id ===
                            profile.staff_profile?.department.id
                        )?.roles,
                        profile?.staff_profile?.role?.id || "",
                        false
                      )}
                  </>
                )}
                {isPermissionGroupsLoading ? (
                  <LoadingIcon />
                ) : (
                  setMultiSelectField(
                    language === "en" ? "Permission Group" : "مصفوفة الصلاحيات",
                    "groups",
                    permissionGroupsSerializer(permission_groups) || [],
                    defaultValueSerializer(profile?.groups),
                    true,
                    "/permission-group/create"
                  )
                )}
                <CardFooter className="flex flex-col items-start gap-4">
                  {formError && (
                    <div className="text-red-500 font-semibold ">
                      {formError}
                    </div>
                  )}
                  {isEditing && (
                    <Button
                      onClick={() => updateProfileData()}
                      className="mt-4 w-full"
                      permission="change_user"
                    >
                      {formLoading ? (
                        <LoadingIcon />
                      ) : language === "en" ? (
                        "Update"
                      ) : (
                        "تحديث"
                      )}
                    </Button>
                  )}
                </CardFooter>
              </div>
              <div>
                {
                  language === "en" ? "User Certificates" : "شهادات المستخدم"
                }
                <div className="flex gap-4 mt-4 flex-wrap">
                  {profile?.staff_profile?.staff_certificates?.map(
                    (certificate: any) => (
                      fileViewer(certificate)
                    )
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
};

export default EditUser;
