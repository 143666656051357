import { useLanguage } from "@/components/context/LanguageContext";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import { format, toZonedTime } from "date-fns-tz";
import { Button } from "@/components/ui/button";
import { categories, tickets } from "@/services/tickets-services";
import { useQuery } from "@tanstack/react-query";
import { CiFilter, CiSettings } from "react-icons/ci";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarTrigger,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
} from "@/components/ui/menubar";
import { IoMdCheckmark } from "react-icons/io";
import { jwtDecode } from "jwt-decode";
import { useCookies } from "react-cookie";
import { Badge } from "@/components/ui/badge"

const RequestDashboard = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [cookies] = useCookies(["Authorization"]);
  const [search, setSearch] = useState<Record<string, string>>({});

  useEffect(() => {
    document.title = "Lazem GO - IT Tasks";
    initializeFilters();
  }, []);
  const queryString = searchParams?.toString();

  const initializeFilters = () => {
    const params: Record<string, string> = {};
    searchParams?.forEach((value, key) => {
      params[key] = value;
    });
    setSearch(params);
  };

  const {
    data: myId,
    isLoading: myIdLoading,
  } = useQuery({
    queryKey: ["my id"],
    queryFn: () => getMyId(),
    refetchOnWindowFocus: false,
  });

  const {
    data: ticketsData,
    isLoading: ticketsIsLoading,
    isFetching: ticketsIsFetching,
    refetch: refetchTickets,
  } = useQuery({
    queryKey: ["tickets dashboard", search],
    queryFn: () => {
      const queryString = new URLSearchParams(search)?.toString();
      if (queryString) {
        return tickets?.list(queryString);
      }
      return tickets?.list();
    },
    refetchOnWindowFocus: false,
  });

  const {
    data: ticketsOptionData,
    isLoading: ticketsOptionLoading,
  } = useQuery({
    queryKey: ["tickets dashboard options"],
    queryFn: tickets?.options,
    refetchOnWindowFocus: false,
  });

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: categories?.list,
    refetchOnWindowFocus: false,
  });

  function formatDate(isoDate: any) {
    const timeZone =
      Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "Asia/Riyadh";
    let formattedDateTime = format(
      toZonedTime(isoDate, timeZone),
      "MMMM d, yyyy hh:mm a",
      { timeZone }
    );
    return formattedDateTime;
  }

  const getMyId = () => {
    const token = cookies?.Authorization;
    const decodedToken: any = jwtDecode(String(token));
    return decodedToken?.user_id;
  };

  const handleFilter = (key: string, value: string) => {
    let updatedFilters = { ...search };

    // If "All" is clicked, clear all filters
    if (key === "" && value === "") {
      updatedFilters = {};
    }
    // If the clicked filter is already active, remove it (toggle off)
    else if (updatedFilters[key] === value) {
      delete updatedFilters[key];
    }
    // Otherwise, set the new filter
    else {
      updatedFilters[key] = value;
    }

    setSearch(updatedFilters);
    navigate(`/requests?${new URLSearchParams(updatedFilters)?.toString()}`);
    refetchTickets();
  };


  return (
    <div dir={language === "en" ? "ltr" : "rtl"} className="mx-2">
      {(ticketsIsLoading || ticketsIsFetching || ticketsOptionLoading || categoriesLoading) && (
        <LoadingScreen />
      )}

      <div className="my-4">
        <h1 className="text-2xl font-bold">
          {language === "en" ? "Requests Dashboard" : "لوحة تحكم الطلبات"}
        </h1>

        <div className="flex justify-end items-end mx-6 my-4 gap-4">
          <div className="flex justify-between items-end mx-6 my-4 gap-4">
            <Button onClick={() => navigate("/")} variant={"secondary"}>
              {language === "en" ? "Home Page" : "الصفحة الرئيسية"}
            </Button>

            <Button
              onClick={() => navigate("/requests/settings?" + queryString)}
              permission="change_categories"
            >
              <CiSettings
                size={40}
                className="cursor-pointer"

              />
            </Button>
          </div>
          <Menubar className="my-4 mx-2">
            <MenubarMenu>
              <MenubarTrigger
                className="flex items-center"

              >
                <CiFilter />
                <span>{language === "en" ? "Filter" : "تصفية"}</span>
              </MenubarTrigger>
              <MenubarContent>
                <MenubarItem onClick={() => handleFilter("", "")}>
                  {language === "en" ? "View All" : "عرض الكل"}
                  {!Object.keys(search).length && (
                    <MenubarShortcut>
                      <IoMdCheckmark />
                    </MenubarShortcut>
                  )}
                </MenubarItem>
                {/* <MenubarSeparator /> */}
                <MenubarItem
                  onClick={() => handleFilter("assigned_to", getMyId())}
                >
                  {language === "en" ? "Assigned to me" : "مخصصة لي"}
                  {search["assigned_to"] === getMyId() && (
                    <MenubarShortcut>
                      <IoMdCheckmark />
                    </MenubarShortcut>
                  )}
                </MenubarItem>
                <MenubarItem onClick={() => handleFilter("assigned_to__isnull", "true")}>
                  {language === "en" ? "Unassigned" : "غير مخصصة"}
                  {search["assigned_to__isnull"] === "true" && (
                    <MenubarShortcut>
                      <IoMdCheckmark />
                    </MenubarShortcut>
                  )}
                </MenubarItem>
                <MenubarItem onClick={() => handleFilter("is_external", "true")}>
                  {language === "en" ? "External Requests" : "الطلبات الخارجية"}
                  {search["is_external"] === "true" && (
                    <MenubarShortcut>
                      <IoMdCheckmark />
                    </MenubarShortcut>
                  )}
                </MenubarItem>
                <MenubarSub>
                  <MenubarSubTrigger>
                    {language === "en" ? "Priority" : "الأولوية"}
                  </MenubarSubTrigger>
                  <MenubarSubContent>
                    {ticketsOptionData &&
                      ticketsOptionData?.data?.actions?.POST?.priority?.choices?.map(
                        (priority: any) => (
                          <MenubarItem
                            key={priority?.value}
                            onClick={() =>
                              handleFilter("priority", priority?.value)
                            }
                          >
                            {priority?.display_name}
                            {search["priority"] === priority?.value && (
                              <MenubarShortcut>
                                <IoMdCheckmark />
                              </MenubarShortcut>
                            )}
                          </MenubarItem>
                        )
                      )}
                  </MenubarSubContent>
                </MenubarSub>
                <MenubarSub>
                  <MenubarSubTrigger>
                    {language === "en" ? "Status" : "الحالة"}
                  </MenubarSubTrigger>
                  <MenubarSubContent>
                    {ticketsOptionData &&
                      ticketsOptionData?.data?.actions?.POST?.status?.choices?.map(
                        (status: any) => (
                          <MenubarItem
                            key={status?.value}
                            onClick={() =>
                              handleFilter("status", status?.value)
                            }
                          >
                            {status?.display_name}
                            {search["status"] === status?.value && (
                              <MenubarShortcut>
                                <IoMdCheckmark />
                              </MenubarShortcut>
                            )}
                          </MenubarItem>
                        )
                      )}
                  </MenubarSubContent>
                </MenubarSub>
                <MenubarSub>
                  <MenubarSubTrigger>
                    {language === "en" ? "Category" : "التصنيف"}
                  </MenubarSubTrigger>
                  <MenubarSubContent>
                    {categoriesData &&
                      categoriesData?.data?.map((category: any) => (
                        <MenubarItem
                          key={category?.id}
                          onClick={() =>
                            handleFilter("category", category?.id)
                          }
                        >
                          {category?.name}
                          {search["category"] === category?.id && (
                            <MenubarShortcut>
                              <IoMdCheckmark />
                            </MenubarShortcut>
                          )}
                        </MenubarItem>
                      ))}
                  </MenubarSubContent>
                </MenubarSub>
              </MenubarContent>
            </MenubarMenu>
          </Menubar>
        </div>
      </div>
      <div>
        <h2 className="text-2xl font-bold my-4 text-start">
          {language === "en" ? "Active Requests" : "الطلبات النشطة"}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {ticketsData &&
            ticketsData?.data?.map((task: any) => (
              task?.status !== "Closed" && (
                <Card
                  key={task?.id}
                  className="cursor-pointer"
                  onClick={() => navigate(`/requests/id/${task?.id}?${queryString}`)}

                >
                  <CardHeader>
                    <div
                      className="flex justify-between items-center"
                    >
                      {task?.assigned_to?.id === myId ?
                        <Badge
                          variant="default"
                        >
                          {language === "en" ? "Assigned to me" : "مخصصة لي"}
                        </Badge> :

                        <Badge
                          variant="secondary"
                        >
                          {language === "en" ? "Department" : "للقسم"}
                        </Badge>
                      }
                      <Badge
                        variant={task?.assigned_to?.id === myId ? "default" : "destructive"}
                      >
                        {task?.status}
                      </Badge>
                      {task?.is_external &&
                        <Badge
                          variant={"secondary"}
                        >
                          {language === "en" ? "External Request" : "طلب خارجي"}
                        </Badge>
                      }
                    </div>
                    <CardTitle
                      className="line-clamp-2"
                    >
                      {task?.title}
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <CardDescription>
                      <div className="my-2 line-clamp-2">{task?.description}</div>
                      <div className="my-2">{formatDate(task?.created_at)}</div>
                    </CardDescription>
                  </CardContent>
                </Card>
              )
            ))}
        </div>
      </div>

      <div>
        <h2 className="text-2xl font-bold my-4 text-start">
          {language === "en" ? "Closed Requests" : "الطلبات المغلقة"}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {ticketsData &&
            ticketsData?.data?.map((task: any) => (
              task?.status === "Closed" && (
                <Card
                  key={task?.id}
                  className="cursor-pointer"
                  onClick={() => navigate(`/requests/id/${task?.id}?${queryString}`)}
                >
                  <CardHeader>
                    <div
                      className="flex justify-between items-center"
                    >
                      <Badge
                        variant={"default"}
                      >
                        {task?.status}
                      </Badge>
                      {task?.is_external &&
                        <Badge
                          variant={"secondary"}
                        >
                          {language === "en" ? "External Request" : "طلب خارجي"}
                        </Badge>
                      }
                    </div>
                    <CardTitle
                      className="line-clamp-2"
                    >
                      {task?.title}
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <CardDescription>
                      <div className="my-2 line-clamp-2">{task?.description}</div>
                      <div className="my-2">{formatDate(task?.created_at)}</div>
                    </CardDescription>
                  </CardContent>
                </Card>
              )
            ))}
        </div>
      </div>
    </div>
  );
};

export default RequestDashboard;
