import ApiUrl from "./api-url";
import axios, { isCancel } from 'axios';
import { ApiHeaderOptions } from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";


const EmailTemplates = {
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/notifications/email_templates/`;
        return axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error,  "medium");
        });
    },
    list: async () => {
        const url = `${ApiUrl}/v1/notifications/email_templates/`;
        return axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/notifications/email_templates/${id}/`;
        return axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "high");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/notifications/email_templates/${id}/`;
        return axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "medium");
        });
    },
    list_email_vars: async () => {
        const url = `${ApiUrl}/v1/notifications/email_templates_vars/`;
        return axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "high");
        });
    },
    delete: async (id: any) => {
        const url = `${ApiUrl}/v1/notifications/email_templates/${id}/`;
        return axios.delete(url, await ApiHeaderOptions()).then((response) => {
            return response.data || []; 
        }).catch((error) => {
            errorHandlingService(error, "medium");
        });
    }
}

const SystemNotifications = {
    list: async () => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/${id}/`;
        return axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "high");
        });
    },
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "medium");
        });
    },
    options: async () => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.options(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "high");
        });
    },
    triggers: async () => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.options(url, await ApiHeaderOptions()).then((response) => {
            return response?.data?.actions?.POST?.trigger?.choices || [];
        }).catch((error) => {
            errorHandlingService(error,"medium");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/${id}/`;
        return axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "medium");
        });
    },
    delete: async (id: any) => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/${id}/`;
        return axios.delete(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "medium");
        });
    }
}

const RetryEmail = async (email_log_id: any) => {
    const url = `${ApiUrl}/v1/notifications/email_retry/`;
    const data = {
        "email_log_id": email_log_id
    }
    return axios.post(url, data, await ApiHeaderOptions()).then((response) => {
        return response.data || [];
    }).catch((error) => {
        errorHandlingService(error, "medium")
    });
}


export { EmailTemplates, SystemNotifications, RetryEmail };