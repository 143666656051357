import ApiUrl from "./api-url";
import axios, {isCancel} from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";

const getSite = async (id:any) => {
    const url = `${ApiUrl}/v1/sites/sites/${id}/`;
    return await axios.get(url, await ApiHeaderOptions()).then((response) => {
        return response || [];
    }).catch((error:any) => {
        errorHandlingService(error,  "high");
    });
}

const getSites = async () => {
    const url = `${ApiUrl}/v1/sites/sites/`;
    return await axios.get(url, await ApiHeaderOptions()).then((response) => {
        return response || [];
    }).catch((error:any) => {
        errorHandlingService(error, "high");
    });
}

const createSite = async (data:any) => {
    const url = `${ApiUrl}/v1/sites/sites/`;
    return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
        return response || [];
    }).catch((error:any) => {
        errorHandlingService(error, "medium");
    });
}

const getSiteMembers = async () => {
    const url = `${ApiUrl}/v1/sites/site_members/`;
    return await axios.get(url, await ApiHeaderOptions()).then((response) => {
        return response || [];
    }).catch((error:any) => {
        errorHandlingService(error, "high");
    });
}

const getSiteMember = async (id:any) => {
    const url = `${ApiUrl}/v1/sites/site_members/${id}/`;
    return await axios.get(url, await ApiHeaderOptions()).then((response) => {
        return response || [];
    }).catch((error:any) => {
        errorHandlingService(error, "high");
    });
}

const createSiteMember = async (data:any) => {
    /* 
    data = {
        "site": site_id,
        "user": user_id,
        "role": "member" or "manager",

    }

    */
    const url = `${ApiUrl}/v1/sites/site_members/`;
    return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
        return response || [];
    }).catch((error:any) => {
        errorHandlingService(error, "medium");
    });
}

const editSite = async (id:any, data:any) => {
    const url = `${ApiUrl}/v1/sites/sites/${id}/`;
    return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
        return response || [];
    }).catch((error:any) => {
        errorHandlingService(error,  "medium");
    });
}

const deleteSite = async (id:any) => {
    const url = `${ApiUrl}/v1/sites/site_members/${id}/`;
    return await axios.delete(url, await ApiHeaderOptions()).then((response) => {
        return response || [];
    }).catch((error:any) => {
        errorHandlingService(error, "medium");
    });
}

export {getSite, getSites, createSite, editSite, getSiteMembers, getSiteMember, createSiteMember, deleteSite};