import { Label } from "./ui/label";
import { format, toZonedTime } from "date-fns-tz";
import { useLanguage } from "./context/LanguageContext";
import { FileMosaic } from "@files-ui/react";
import { useTheme } from "./theme-provider";
import "./log-feed.css";
import { is_arabic, is_english } from "@/services/language-checker";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { userFullNameSerializer } from "@/services/user-service";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useState } from "react";
import { Button } from "./ui/button";
import { FiDownload } from "react-icons/fi";

export default function LogFeed({ logs }: any) {
  const { language } = useLanguage();
  const { theme } = useTheme();
  const [enlargedFile, setEnlargedFile] = useState<any>(null);
  const [isEnlargedFile, setIsEnlargedFile] = useState(false);
  
  const convertDate = (date: any) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Riyadh";
    return format(toZonedTime(date, timeZone), "MMMM d, yyyy hh:mm a", { timeZone });
  };

  const fileViewer = (file: any) => {
    let fileType = file?.file?.split("?")[0].split(".").pop();
    let fileName = file?.file?.split("/").pop().split("?")[0];
    if (fileType === "pdf") fileType = "application/pdf";

    if (fileType === "application/pdf") {
      return (
        <Dialog
          open={isEnlargedFile && enlargedFile?.id === file.id}
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setIsEnlargedFile(false);
            }
          }}
        >
          <DialogTrigger asChild>
            <div
              style={{ cursor: 'pointer', display: 'inline-block',  }}
              onClick={() => {
                setEnlargedFile(file);
                setIsEnlargedFile(true);
              }}
            >
              <Button
                variant="link"
                className="text-sm"
                onClick={() => {
                  setEnlargedFile(file);
                  setIsEnlargedFile(true);
                }}
              >
                {language === "en" ? "View" : "عرض"}
              </Button>
              <iframe
                onClick={() => {
                  setEnlargedFile(file);
                  setIsEnlargedFile(true);
                }}
                src={file?.file}
                width="100%"
                height="100%"
                style={{ border: "none" }}
              ></iframe>
            </div>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[80vw] h-[100vh] p-8">
            <iframe
              src={file?.file}
              width="100%"
              height="100%"

              style={{ border: "none", borderRadius: "10px", height: "100%", minHeight: "80vh" }}
            ></iframe>
          </DialogContent>
        </Dialog>
      );
    }
    return (
      <FileMosaic
        id={file?.id}
        type={fileType}
        name={fileName}
        imageUrl={file?.file}
        darkMode={theme === "dark"}
        preview
        onClick={() => window.open(file?.file, "_blank")}
        className="transition-transform duration-200 hover:scale-105 mt-10"
      />
    );
  };

  const hoverUserCard = (user: any, is_system: boolean) => {
    if (language == "ar") {
      if (is_system) {
        return (
          <div className="flex space-x-4">
            <div className="space-y-1">
              <h4 className="text-sm font-semibold text-right">GO System</h4>
              <p className="text-sm">
                تم انشاء هذا التعليق من النظام
              </p>
            </div>
          </div>
        )
      }
      return (
        <div className="flex space-x-4">
          <div className="space-y-1">
            <h4 className="text-sm font-semibold text-right">{userFullNameSerializer(user, language)}</h4>
            <p className="text-sm">
              {user?.email}
            </p>
          </div>
        </div>
      )

    }
    if (is_system) {
      return (
        <div className="flex space-x-4">
          <div className="space-y-1">
            <h4 className="text-sm font-semibold text-left">@GO System</h4>
            <p className="text-sm">
              System generated log
            </p>
          </div>
        </div>
      )
    }
    return (
      <div className="flex space-x-4">
        <div className="space-y-1">
          <h4 className="text-sm font-semibold text-left">{userFullNameSerializer(user, language)}</h4>
          <p className="text-sm">
            {user?.email}
          </p>
        </div>
      </div>
    )
  }

  const isContentArabic = (content: string) => {
    return is_arabic(content?.split(" ")[0]);
  }

  return (
    <div
      className="w-full mx-auto mt-12 p-4 rounded-lg shadow-lg"
      dir={language === "en" ? "ltr" : "rtl"}
    >
      <ul
        aria-label="User feed"
        role="feed"
        className="relative flex flex-col gap-10 py-8 pl-6 before:absolute before:top-0 before:left-6 before:h-full before:-translate-x-1/2 before:border before:border-dashed before:border-gray-300 dark:before:border-gray-600"
      >
        {logs?.map((log: any, index: number) => (
          <li key={index} role="article" className="relative pl-6 group">
            <div className="flex flex-col gap-3 bg-gray-100 dark:bg-gray-800 p-4 rounded-lg shadow-sm h-full">
              <div className="absolute -left-3 top-4 z-10 flex items-center justify-center w-6 h-6 text-white rounded-full ring-2 ring-white bg-green-500 group-hover:scale-110 transition-transform">
              </div>
              <div className="flex  md:flex-row md:items-center justify-between text-base font-medium hidden lg:flex">
                <HoverCard
                  openDelay={100}
                >
                  <HoverCardTrigger asChild>
                    <Label className={`flex-1 text-start ${log?.is_system ? "text-cyan-700 dark:text-cyan-300" : "text-gray-900 dark:text-gray-200"} underline`}>
                      {log?.is_system ? "GO System" : userFullNameSerializer(log?.created_by, language)}
                    </Label>
                  </HoverCardTrigger>

                  <HoverCardContent className="w-auto">
                    {hoverUserCard(log?.created_by, log?.is_system)}
                  </HoverCardContent>
                </HoverCard>
                <Label className="text-sm text-gray-500 dark:text-gray-400">
                  {convertDate(log?.created_at)}
                </Label>
              </div>
              <div className="flex  md:flex-row md:items-center justify-between text-base font-medium lg:hidden">
                <Popover
                >
                  <PopoverTrigger asChild>
                    <Label className={`flex-1 text-start ${log?.is_system ? "text-cyan-700 dark:text-cyan-300" : "text-gray-900 dark:text-gray-200"} underline`}>
                      {log?.is_system ? "GO System" : userFullNameSerializer(log?.created_by, language)}
                    </Label>
                  </PopoverTrigger>

                  <PopoverContent className="w-auto">
                    {hoverUserCard(log?.created_by, log?.is_system)}
                  </PopoverContent>
                </Popover>
                <Label className="text-sm text-gray-500 dark:text-gray-400">
                  {convertDate(log?.created_at)}
                </Label>
              </div>
              <Label className={`text-gray-700 dark:text-gray-300 whitespace-pre-line text-end ${isContentArabic(log?.comment || log?.message) ? " text-right" : "text-left"}`} style={{ direction: isContentArabic(log?.comment || log?.message) ? "rtl" : "ltr" }}>
                {log?.comment || log?.message}
              </Label>
              {log?.attachments?.length > 0 && (
                <div className="flex flex-wrap gap-2  ">
                  {log.attachments.map((attachment: any, idx: number) => (
                    <div key={idx}>{fileViewer(attachment)}</div>
                  ))}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}