// TaskStageForm.tsx
import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import InputField from "./InputField";
import SelectField from "./SelectField";
import TaskTemplates from "./TaskTemplates";
import { Button } from "@/components/ui/button";
import { MdDeleteForever } from "react-icons/md";
import { useLanguage } from "@/components/context/LanguageContext";

interface TaskStageFormProps {
  taskStage: any;
  stageIndex: number;
  onChange: (e: any, table: string, taskStageIndex?: number | null, templateIndex?: number | null) => void;
  onRemoveTaskStage: (index: number) => void;
  onAddTaskTemplate: (taskStageIndex: number) => void;
  onRemoveTaskTemplate: (taskStageIndex: number, templateIndex: number) => void;
  fetchedProjectStages: any[];
  fetchedDepartments: any[];
  fetchedTaskTemplates: any[];
  taskTemplatesType: any[];
  taskTemplatesAssignedGroup: any[];
  taskTemplatesGroupRole: any[];
  fetchedFormTemplates: any[];
  isFetchingFormTemplates: boolean;
  isFetchingDepartments: boolean;
  functionToCall: (field: string) => void;
}

const TaskStageForm: React.FC<TaskStageFormProps> = ({
  taskStage,
  stageIndex,
  onChange,
  onRemoveTaskStage,
  onAddTaskTemplate,
  onRemoveTaskTemplate,
  fetchedProjectStages,
  fetchedDepartments,
  fetchedTaskTemplates,
  taskTemplatesType,
  taskTemplatesAssignedGroup,
  taskTemplatesGroupRole,
  fetchedFormTemplates,
  isFetchingFormTemplates,
  isFetchingDepartments,
  functionToCall,
}) => {
  const { language } = useLanguage();

  return (
    <Card key={stageIndex} className="flex gap-2 flex-wrap justify-between my-4 p-2">
      <div className="flex justify-end w-full">
        <Button variant={"destructive"} onClick={() => onRemoveTaskStage(stageIndex)}>
          <MdDeleteForever
            className="hover:text-red-500"
            size={20}
            title="Delete Task Stage"
          />
        </Button>
      </div>
      <div className="w-full">
        <CardTitle className="text-xl font-bold text-center my-2">
          {language === "en" ? "Stage Number" : "رقم المرحلة"} {stageIndex + 1}
        </CardTitle>
      </div>
      <div className="w-full lg:w-2/5">
        <InputField
          label={language === "en" ? "Task Stage Title" : "عنوان مرحلة المهمة"}
          field="title"
          value={taskStage.title}
          table="taskStages"
          type="text"
          taskStageIndex={stageIndex}
          onChange={onChange}
        />
      </div>
      <div className="w-full lg:w-2/5">
        <InputField
          label={language === "en" ? "Description" : "الوصف"}
          field="description"
          value={taskStage.description}
          table="taskStages"
          type="text"
          taskStageIndex={stageIndex}
          onChange={onChange}
        />
      </div>
      <div className="w-full">
        <SelectField
          label={language === "en" ? "Stage" : "المرحلة"}
          field="stage"
          value={taskStage.stage}
          options={fetchedProjectStages}
          table="taskStages"
          taskStageIndex={stageIndex}
          onChange={onChange}
          withAdd={true}
          url="/projects/create-stage"
          functionToCall={functionToCall}
        />
      </div>

      <TaskTemplates
        taskStages={[{ task_templates: taskStage.task_templates }]} // Structure adapted for component's prop expectation
        taskStageIndex={0} //  Fixed index since we are passing single stage's templates
        onChange={onChange}
        onRemoveTaskTemplate={onRemoveTaskTemplate}
        onAddTaskTemplate={onAddTaskTemplate}
        fetchedTaskTemplates={fetchedTaskTemplates}
        fetchedDepartments={fetchedDepartments}
        taskTemplatesType={taskTemplatesType}
        taskTemplatesAssignedGroup={taskTemplatesAssignedGroup}
        taskTemplatesGroupRole={taskTemplatesGroupRole}
        fetchedFormTemplates={fetchedFormTemplates}
        isFetchingFormTemplates={isFetchingFormTemplates}
        isFetchingDepartments={isFetchingDepartments}
        functionToCall={functionToCall}
      />
    </Card>
  );
};

export default TaskStageForm;