import ApiUrl from "./api-url";
import axios, { isCancel } from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";

const forms = {
    list: async () => {
        const url = `${ApiUrl}/v1/forms/forms/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error,  "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/forms/forms/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    create: async (data: any) => {
        /*
        data = {
            "form_template": 1
        }
        */
        // Create is not ready yet
        const url = `${ApiUrl}/v1/forms/forms/`;
        return "Create is not ready yet";
        // return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
        //     return response;
        // }).catch((error:any) => {
        //     if (isCancel(error)) {
        //         console.log('Request canceled', error);
        //     
        //     } else {
        //         console.log('Error', error);
        //     
        //     }
        // });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/forms/forms/${id}/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
}

const formTemplates = {
    list: async () => {
        const url = `${ApiUrl}/v1/forms/form_templates/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/forms/form_templates/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    create: async (data: any) => {
        /*
        data = {
            "title": "A Title",
            "description": "A Description",
            "form_type": "form",
            "is_multiple": false,
            "questions": [
                {
                    "question": "A Question",
                    "question_type": "text",
                    "is_required": false,
                    "index": 2
                },
                {
                    "question": "Q2: Testo",
                    "question_type": "select",
                    "is_required": true,
                    "choices": ["Answer 1", "Answer 2"], // Required for select question_type. list of strings as choices
                    "index": 1 // Required for ordering questions
                }
            ]
        }
        */
        const url = `${ApiUrl}/v1/forms/form_templates/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    question_types: async () => {
        // Get question types for form templates
        const url = `${ApiUrl}/v1/forms/questions/`;
        return await axios.options(url, await ApiHeaderOptions()).then((response: any) => {
            return response.data.actions.POST.question_type.choices || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    form_types: async () => {
        // Get form types for form templates
        const url = `${ApiUrl}/v1/forms/form_templates/`;
        return await axios.options(url, await ApiHeaderOptions()).then((response: any) => {
            return response.data.actions.POST.form_type.choices || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/forms/form_templates/${id}/`;
        return await axios.put(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
}

const formSubmissions = {
    list: async () => {
        const url = `${ApiUrl}/v1/forms/submissions/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/forms/submissions/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    create: async (data: any) => {
        /*
        data = {
            "form": "2d249218-0391-4912-b4a5-1d5c2e69adcf", // form id
            "answers": [
                {
                    "answer": [
                        "Answer 1"
                    ],
                    "question_rel": 27 // question id
                    "files":[1,3,4] // file ids (optional)
                },
                {
                    "answer": [
                        "Testing"
                    ],
                    "question_rel": 26 // question id
                }
            ]
        }
        */
        const url = `${ApiUrl}/v1/forms/submissions/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
}
const formFiles = {
    list: async () => {
        const url = `${ApiUrl}/v1/forms/files/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/forms/files/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    create: async (data: any) => {
        /*
        You must send the file as form data
        data = "file": file
        */
        const url = `${ApiUrl}/v1/forms/files/`;
        return await axios.post(url, data, await ApiHeaderOptions(undefined)).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
}

export { forms, formTemplates, formSubmissions, formFiles };