// TaskStagesForm.tsx
import React from "react";
import TaskStageForm from "./TaskStageForm";
import { Button } from "@/components/ui/button";
import { CardTitle } from "@/components/ui/card";
import { useLanguage } from "@/components/context/LanguageContext";

interface TaskStagesFormProps {
  type: any; // Assuming type.task_stages is the array of task stages
  onChange: (e: any, table: string, taskStageIndex?: number | any, templateIndex?: number | any) => void;
  onRemoveTaskStage: (index: number) => void;
  onAddTaskStage: () => void;
  onAddTaskTemplate: (taskStageIndex: number) => void;
  onRemoveTaskTemplate: (taskStageIndex: number, templateIndex: number) => void;
  fetchedProjectStages: any[];
  fetchedDepartments: any[];
  fetchedTaskTemplates: any[];
  taskTemplatesType: any[];
  taskTemplatesAssignedGroup: any[];
  taskTemplatesGroupRole: any[];
  fetchedFormTemplates: any[];
  isFetchingFormTemplates: boolean;
  isFetchingDepartments: boolean;
  functionToCall: (field: string) => void;
}

const TaskStagesForm: React.FC<TaskStagesFormProps> = ({
  type,
  onChange,
  onRemoveTaskStage,
  onAddTaskStage,
  onAddTaskTemplate,
  onRemoveTaskTemplate,
  fetchedProjectStages,
  fetchedDepartments,
  fetchedTaskTemplates,
  taskTemplatesType,
  taskTemplatesAssignedGroup,
  taskTemplatesGroupRole,
  fetchedFormTemplates,
  isFetchingFormTemplates,
  isFetchingDepartments,
  functionToCall,
}) => {
  const { language } = useLanguage();

  return (
    <div className="flex flex-col my-6 p-2">
      <CardTitle className="text-xl font-bold text-center my-2">
        {language === "en" ? "Task Stages" : "مراحل المهمة"}
      </CardTitle>

      <>
        {type.task_stages.map((taskStage: any, index: number) => (
          <TaskStageForm
            key={index}
            taskStage={taskStage}
            stageIndex={index}
            onChange={onChange}
            onRemoveTaskStage={onRemoveTaskStage}
            onAddTaskTemplate={onAddTaskTemplate}
            onRemoveTaskTemplate={onRemoveTaskTemplate}
            fetchedProjectStages={fetchedProjectStages}
            fetchedDepartments={fetchedDepartments}
            fetchedTaskTemplates={fetchedTaskTemplates}
            taskTemplatesType={taskTemplatesType}
            taskTemplatesAssignedGroup={taskTemplatesAssignedGroup}
            taskTemplatesGroupRole={taskTemplatesGroupRole}
            fetchedFormTemplates={fetchedFormTemplates}
            isFetchingFormTemplates={isFetchingFormTemplates}
            isFetchingDepartments={isFetchingDepartments}
            functionToCall={functionToCall}
          />
        ))}
        <div>
          <Button onClick={onAddTaskStage}>
            {language === "en" ? "Add Task Stage" : "إضافة مرحلة المهمة"}
          </Button>
        </div>
      </>
    </div>
  );
};

export default TaskStagesForm;