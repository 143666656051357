import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import LogFeed from "@/components/log-feed";
import LoadingIcon from "@/components/loading/loading-icon";
import EmployeeCardPrintTemplate from "@/components/employee-card-print-template";
import { Button } from "@/components/ui/button";
import html2canvas from "html2canvas";
import EmployeeCardViewTemplate from "@/components/employee-card-view-template";
import { card_logs, cards } from "@/services/user-service";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { is_arabic, is_english } from "@/services/language-checker";
import { categories, tickets } from "@/services/tickets-services";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { GiCheckMark } from "react-icons/gi";

const CardView = () => {
    const { language } = useLanguage();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [card, setCard] = useState<any>();
    const [isNotifySuccess, setIsNotifySuccess] = useState(false);
    const [cardStatusList, setCardStatusList] = useState<any>([
        { id: "need_update", display_name: "Need Update", display_name_ar: "تحتاج تحديث", progress: 0, complete_display_name: "Updated", complete_display_name_ar: "تم التحديث" },
        { id: "ready", display_name: "Ready for printing", display_name_ar: "جاهزة للطباعة", progress: 25, complete_display_name: "Ready to print", complete_display_name_ar: "جاهزة للطباعة" },
        { id: "requested", display_name: "Request sent", display_name_ar: "تم إرسال طلب", progress: 50, complete_display_name: "Requested", complete_display_name_ar: "تم الطلب" },
        { id: "printed", display_name: "Waiting for print", display_name_ar: "في انتظار الطباعة", progress: 75, complete_display_name: "Printed", complete_display_name_ar: "تمت الطباعة" },
        { id: "delivered", display_name: "Waiting for delivery", display_name_ar: "في انتظار التسليم", progress: 100, complete_display_name: "Delivered", complete_display_name_ar: "تم التسليم" },
        // { id: "expired", display_name: "Expired", display_name_ar: "منتهية", progress: -1 },
    ]);
    const [employeeDetail, setEmployeeDetail] = useState<any>({
        title: "Request Employee Card",
        support_type: "employee_card",
        description: "Request Employee Card for",
        priority: "High",
        json_request: [
        ],
        category: null,
    });

    const {
        data: cardsData,
        isLoading: cardsLoading,
        isFetching: cardsFetching,
        refetch: refetchCards,
    } = useQuery({
        queryKey: ["cards management"],
        queryFn: () => cards.get(id).then((res:any) => {
            document.title = res?.data?.user?.full_name + " - Card ";
            setCard(res?.data);
            return res?.data;
        }),
        refetchOnWindowFocus: false,
    });
    const contentRef = useRef<any>();


    const handlePrint = async () => {
        setLoading(true);
        setTimeout(async () => {
            const targetDiv = document.getElementById("component-to-print");
            if (targetDiv) {
                try {
                    const canvas = await html2canvas(targetDiv, {
                        scrollX: 0,
                        scrollY: -window.scrollY,
                        useCORS: true,
                        allowTaint: true,

                    });
                    const imageData = canvas.toDataURL("image/png");
                    const downloadLink = document.createElement("a");
                    downloadLink.href = imageData;
                    downloadLink.download = cardsData?.user?.full_name + " card.png";
                    downloadLink.click();
                } catch (error) {
                    console.error("Error converting div to image:", error);
                }
            } else {
                console.error("Target div not found");
            }
            setLoading(false);
        }, 1000);
    };

    const handleStatusChange = (selectedStatus: any) => {
        setLoading(true);
        let card_log_data = {
            card: id,
            message: "Status changed to " + selectedStatus + "\n " + "تم تغيير الحالة الى " + selectedStatus,
            new_status: selectedStatus,
        }
        card_logs.create(card_log_data).then(async (res) => {
            await refetchCards();
            setLoading(false);
        }).catch((error) => {
            console.log("Error", error);
        })
    }

    const fillTheForm = () => {
        setLoading(true);
        const employeeDetailCopy = { ...employeeDetail };
        employeeDetailCopy.category = categoryData?.data[0]?.id;
        employeeDetailCopy.service_type = "print_employee_card";
        employeeDetailCopy.json_request = [
            {
                question_en: "Employee full name EN",
                question_ar: "اسم الموظف بالانجليزية",
                answer: cardsData?.user?.full_name,
                is_required: true,
                name: "employee_full_name_en",
            },
            {
                question_en: "Employee full name AR",
                question_ar: "اسم الموظف بالعربية",
                answer: cardsData?.user?.full_name_ar,
                is_required: true,
                name: "employee_full_name_ar",
            },
            {
                question_en: "Employee ID",
                question_ar: "الرقم الوظيفي",
                answer: cardsData?.employee_id,
                is_required: true,
                name: "employee_id",
            },
            {
                question_en: "Employee Position EN",
                question_ar: "منصب الموظف بالانجليزية",
                answer: cardsData?.role,
                is_required: true,
                name: "employee_position",
            },
            {
                question_en: "Employee Position AR",
                question_ar: "منصب الموظف بالعربية",
                answer: cardsData?.role_ar,
                is_required: true,
                name: "employee_position_ar",
            },
            {
                question_en: "Employee Email",
                question_ar: "بريد الموظف",
                answer: cardsData?.user?.email,
                is_required: true,
                name: "employee_email",
            },
        ];
        tickets.create(employeeDetailCopy).then((response:any) => {
            let card_log_data = {
                card: id,
                message: "Print request has been created Ticket ID: " + response?.data?.id + "\n " + "تم انشاء طلب الطباعة برقم الطلب: " + response?.data?.id,
                new_status: "requested",
            }
            card_logs.create(card_log_data).then(async (res) => {
                await refetchCards();
                setLoading(false);

            }).catch((error) => {
                console.log("Error", error);
            })
        }).catch((error) => {
            console.log("Error", error);
        });
    }

    const handleNotifyTheUser = () => {
        setLoading(true);
        cards.notify_employee(card?.id).then(async () => {
            // TODO: Success message
            setIsNotifySuccess(true);
            await refetchCards();
            setLoading(false);
        }).catch((error) => {
            console.log("Error", error);
            // TODO: Error handling
            setLoading(false);
            setIsNotifySuccess(false);
        });
    }
    const isReady = () => {
        return card?.status === "ready";
    }
    const {
        data: categoryData,
        isLoading: categoryLoading,
    }:any = useQuery({
        queryKey: ["category open ticket"],
        queryFn: async () => categories.getWithFilter("tag=print_employee_card"),
        refetchOnWindowFocus: false,
        enabled: isReady()
    });

    return (
        <div
            dir={language === "en" ? "ltr" : "rtl"}
            className="flex flex-col"
        >
            {(cardsLoading || cardsFetching) && <LoadingScreen />}

            {cardsData &&
                <>

                    <Card
                        className="mt-8 w-full md:w-4/5 lg:w-3/5 mx-auto p-2"
                    >
                        <CardHeader>
                            <Button
                                onClick={() => navigate(-1)}
                                className="w-full"
                                variant={"secondary"}
                            >
                                {language === "en" ? "Back" : "رجوع"}
                            </Button>
                        </CardHeader>
                        <EmployeeCardViewTemplate
                            id={card?.employee_id}
                            image={card?.photo}
                            ar_full_name={card?.user?.full_name_ar}
                            en_full_name={card?.user?.full_name}
                            ar_role={card?.role_ar}
                            en_role={card?.role}
                            gender={card?.gender}
                        />

                        {
                            (card?.status != "need_update" && card?.status != "ready") &&
                            
                            <div
                                className="flex justify-center gap-4 mt-4"
                            >
                                <Button
                                    onClick={() => handlePrint()}
                                    disabled={loading}
                                >
                                    {language === "en" ? "Print a copy" : "طباعة نسخة"}
                                    {loading && <LoadingIcon />}
                                </Button>
                            </div>

                        }
                    </Card>

                    <div
                        className={`${loading ? "block" : "hidden"} opacity-0 fixed top-0 left-0 w-full h-full bg-black z-50`}
                    >
                        <EmployeeCardPrintTemplate
                            id={card?.employee_id}
                            ar_full_name={card?.user?.full_name_ar}
                            en_full_name={card?.user?.full_name}
                            ar_role={card?.role_ar}
                            en_role={card?.role}
                            image={card?.photo}
                            gender={card?.gender}
                            ref={contentRef}
                        />
                    </div>

                    <Card
                        dir={language === "en" ? "ltr" : "rtl"}
                        className="mt-8 w-full md:w-4/5 lg:w-3/5 mx-auto"
                    >

                        {
                            card?.status === "ready" &&
                            <div
                                className="p-4 flex flex-col gap-4 w-full md:w-4/5 lg:w-3/5 mx-auto"
                            >
                                <p
                                    className="text-center w-full font-bold text-lg"
                                >
                                    {language === "en" ? "The card is ready. Please download the card demo to check the card before printing" : "البطاقة جاهزة. يرجى تنزيل العرض التجريبي للبطاقة للتحقق منها قبل الطباعة"}
                                </p>
                                <Button
                                    onClick={() => handlePrint()}
                                    disabled={loading || card?.status !== "ready"}
                                    variant={"secondary"}
                                >
                                    {language === "en" ? "View demo before printing" : "عرض تجريبي قبل الطباعة"}
                                    {loading && <LoadingIcon />}
                                </Button>
                                <Button
                                    onClick={() => fillTheForm()}
                                    disabled={loading || card?.status !== "ready"}
                                >
                                    {language === "en" ? "Print Request" : "طلب الطباعة"}
                                    {loading && <LoadingIcon />}
                                </Button>
                            </div>
                        }
                        <div className="flex justify-center gap-4 mt-6 flex-col">
                            {
                                card?.status === "requested" &&
                                <>
                                    <p
                                        className="text-center w-full font-bold text-lg"
                                    >
                                        {language === "en" ? "The card is requested, and waiting for the card to be printed. Please check the logs for more information" : "تم طلب البطاقة وفي انتظار طباعة البطاقة. يرجى التحقق من السجلات لمزيد من المعلومات"}
                                    </p>

                                    <Button
                                        onClick={() => handleStatusChange("printed")}
                                        disabled={loading}
                                        className="w-fit mx-4"
                                    >
                                        {language === "en" ? "Click here if the card is printed" : "انقر هنا إذا تمت الطباعة"}
                                        {loading && <LoadingIcon />}
                                    </Button>
                                </>
                            }
                            {
                                card?.status === "printed" &&
                                <>
                                    <p
                                        className="text-center w-full font-bold text-lg"
                                    >
                                        {language === "en" ? "Please hand over the card to the employee" : "يرجى تسليم البطاقة للموظف"}
                                    </p>
                                    <Button
                                        onClick={() => handleStatusChange("delivered")}
                                        disabled={loading}
                                        className="w-fit mx-4"
                                    >
                                        {language === "en" ? "Click here if the card is delivered" : "انقر هنا إذا تم تسليم البطاقة"}
                                        {loading && <LoadingIcon />}
                                    </Button>
                                </>
                            }
                            {
                                card?.status === "delivered" &&
                                <>
                                    <p
                                        className="text-center w-full font-bold text-lg"
                                    >
                                        {language === "en" ? "The card has been delivered" : "تم تسليم البطاقة"}
                                    </p>
                                </>
                            }
                            {
                                card?.status === "expired" &&
                                <p
                                    className="text-red-500 text-center w-full font-bold "
                                >
                                    {language === "en" ? "The card is expired" : "انتهت صلاحية البطاقة"}
                                </p>
                            }
                        </div>
                        <Breadcrumb
                            className="mt-4 w-full m-auto flex justify-center p-4"
                        >
                            <BreadcrumbList
                            >
                                {cardStatusList.map((status: any, index: any) => {
                                    const currentIndex = cardStatusList.findIndex((s: any) => s.id === card?.status);
                                    const isCompleted = index < currentIndex;
                                    const isCurrent = index === currentIndex;
                                    const isNext = index === currentIndex + 1;
                                    const isNeedUpdate = card?.status === "need_update" || card?.status === "ready";
                                    const statusName = language === "en" ? status.display_name : status.display_name_ar;

                                    return (
                                        <>
                                            <BreadcrumbItem key={status.id}>
                                                {((isCompleted || isCurrent) && !isNeedUpdate) && <GiCheckMark className="text-green-500" />}
                                                <BreadcrumbLink
                                                    className={`
                                                        ${isCompleted ? "text-green-500" : ""} 
                                                        ${isCurrent && isNeedUpdate ? "font-bold text-yellow-500 animate-pulse" : isCurrent ? "font-bold text-green-500" : ""} 
                                                        ${isNext && !isNeedUpdate ? "font-bold text-yellow-500 animate-pulse" : ""}
                                                        ${card?.status === "delivered" ? "text-green-500 animate-none" : ""}
                                                    `}
                                                >
                                                    {statusName}
                                                </BreadcrumbLink>
                                            </BreadcrumbItem>
                                            {index < cardStatusList.length - 1 && (
                                                <BreadcrumbSeparator dir={language === "en" ? "ltr" : "rtl"} />
                                            )}
                                        </>
                                    );
                                })}
                            </BreadcrumbList>
                        </Breadcrumb>


                        {card?.status === "need_update" &&
                            <>
                                <CardHeader>
                                    <CardTitle>
                                        {language === "en" ? "The following information needs to be updated before printing the card" : "المعلومات التالية تحتاج الى تحديث قبل طباعة البطاقة"}
                                    </CardTitle>
                                </CardHeader>
                                <CardContent
                                    className="text-start"
                                >
                                    <CardDescription>
                                        {
                                            (!is_english(card?.user?.full_name)) &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "English full name" : "الاسم الكامل بالانجليزي"}
                                            </p>
                                        }
                                        {
                                            (!is_arabic(card?.user?.full_name_ar)) &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "Add full name in Arabic" : "اضافة الاسم الكامل بالعربي"}
                                            </p>
                                        }
                                        {
                                            !card?.role_ar &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "Add role in Arabic" : "اضافة الدور بالعربي"}
                                            </p>
                                        }
                                        {
                                            !card?.employee_id &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                *  {language === "en" ? "Add employee ID" : "اضافة الرقم الوظيفي"}
                                            </p>
                                        }
                                        {
                                            !card?.gender &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "Add employee gender" : "اضافة جنس الموظف"}
                                            </p>
                                        }
                                        {
                                            (!card?.photo && (card?.gender?.toLowerCase() === "male" || !card?.user?.gender)) &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                *  {language === "en" ? "Add employee photo" : "اضافة صورة الموظف"}
                                            </p>
                                        }

                                    </CardDescription>
                                </CardContent>

                                {!isNotifySuccess &&
                                    <Button
                                        onClick={handleNotifyTheUser}
                                        className="w-fit my-4"
                                        disabled={loading}
                                    >
                                        {language === "en" ? "Notify Employee" : "إخطار الموظف"}
                                        {loading && <LoadingIcon />}
                                    </Button>
                                }
                                {isNotifySuccess &&
                                    <p
                                        className="text-green-500 text-center w-full font-bold text-xl"
                                    >
                                        {language === "en" ? "An email has been sent to the employee" : "تم ارسال بريد الكتروني للموظف"}
                                    </p>
                                }
                            </>
                        }
                        <LogFeed logs={card?.employee_card_logs} />
                    </Card>
                </>
            }

        </div>
    );
};

export default CardView;