import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { useNavigate } from "react-router-dom";

const NotAuthorizedError = ({ error,code,onClose }: any) => {
    const { language } = useLanguage();
    const navigate = useNavigate();

    return (
        <div
            className="h-screen w-full fixed z-50 left-0 top-0 bg-black bg-opacity-90 flex flex-col"
        >
            <Card
                className="m-3 w-64 h-auto flex flex-col justify-content-center align-items-center m-auto"
            >
                <CardHeader
                    className="w-full flex justify-content-center align-items-center m-auto"
                >
                    <CardTitle
                        className="text-2xl font-bold flex justify-content-center align-items-center m-auto"
                    >
                        {language === 'en' ? 'You are not authorized to view this page' : 'غير مصرح لك بعرض هذه الصفحة'}

                    </CardTitle>
                </CardHeader>
                <CardContent
                    className="w-full flex justify-content-center align-items-center m-auto"
                >
                    {error}
                </CardContent>
                <CardFooter
                    className="w-full flex justify-content-center align-items-center m-auto"
                >
                    <CardDescription
                        className="text-lg font-medium flex justify-content-center align-items-center m-auto"
                    >
                    </CardDescription>
                    <div
                        className="flex flex-col justify-content-center align-items-center m-auto w-full gap-4"
                    >
                        <Button
                            onClick={() => {
                                onClose()
                                navigate('/')
                            }}
                        >
                            {language === 'en' ? 'Go to Home' : 'الذهاب إلى الصفحة الرئيسية'}
                        </Button>
                        <Button
                            onClick={() => {
                                onClose()
                                navigate(-1)
                            }}
                        >
                            {language === 'en' ? 'Back' : 'رجوع'}
                        </Button>
                    </div>

                </CardFooter>
            </Card>
        </div>
    );
};

export default NotAuthorizedError;