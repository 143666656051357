import { useState } from "react"
import { useLanguage } from "@/components/context/LanguageContext"
import LoadingScreen from "@/components/loading/loading-screen"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { shortener } from "@/services/url-shortener-services"
import { useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

const ShortenerLinksDashboard = () => {
    const { language } = useLanguage()
    const navigate = useNavigate()
    const [search, setSearch] = useState("")

    const {
        data: shortenerLinksData,
        isLoading: shortenerLinksIsLoading,
    }:any = useQuery({
        queryKey: ['shortenerLinks Dashboard'],
        queryFn: shortener.list,
        refetchOnWindowFocus: false,
    })

    const filteredLinks = shortenerLinksData?.data?.filter((shortenerLink: any) =>
        shortenerLink.short_code.toLowerCase().includes(search.toLowerCase())
    )

    return (
        <div className="p-4" dir={language === 'en' ? 'ltr' : 'rtl'}>
            <div className="flex justify-between items-center mb-4">
                <Button
                    className="m-3 flex"
                    onClick={() => navigate('/shortener-links/create')}
                    permission="add_shortener"
                >
                    {language === 'en' ? 'Create Shortener Link' : 'إنشاء رابط مختصر'}
                </Button>

                <Input
                    type="text"
                    placeholder={language === 'en' ? 'Search by Short Code...' : 'ابحث برمز الاختصار...'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="w-64 border px-3 py-2"
                />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                {shortenerLinksIsLoading && <LoadingScreen />}
                {filteredLinks?.length > 0 ? (
                    filteredLinks.map((shortenerLink: any) => (
                        <Card key={shortenerLink?.id} className="text-start">
                            <CardHeader>
                                <div className="flex items-center justify-between">
                                    <Badge className="w-fit m-1 px-4 py-1">
                                        {shortenerLink?.click_count} {language === 'en' ? 'Clicks' : 'نقرات'}
                                    </Badge>
                                    <Badge className="w-fit m-1 px-4 py-1">
                                        {language === 'en' ? (shortenerLink?.is_active ? "Active" : "Not Active") : (shortenerLink?.is_active ? "نشط" : "غير نشط")}
                                    </Badge>
                                </div>
                                <CardTitle className="text-sm line-clamp-2">
                                    {language === 'en' ? 'Original URL' : 'الرابط الأصلي'}:{' '}
                                    {shortenerLink?.original_url}
                                </CardTitle>
                            </CardHeader>
                            <CardContent>
                                <CardDescription>
                                    <Label>{language === 'en' ? 'Short URL' : 'الرابط المختصر'}:{" "}</Label>
                                    <Label>
                                        <a href={"https://link.lazem.sa/" + shortenerLink?.short_code} target="_blank" rel="noreferrer" className="text-blue-500 underline">
                                            {"https://link.lazem.sa/" + shortenerLink?.short_code}
                                        </a>
                                    </Label>
                                </CardDescription>
                            </CardContent>
                            <CardFooter>
                                <Button onClick={() => navigate(`/shortener-links/short-code/${shortenerLink?.short_code}`)}>
                                    {language === 'en' ? 'View' : 'عرض'}
                                </Button>
                            </CardFooter>
                        </Card>
                    ))
                ) : (
                    <p className="col-span-full text-center text-gray-500">
                        {language === 'en' ? 'No results found' : 'لم يتم العثور على نتائج'}
                    </p>
                )}
            </div>
        </div>
    )
}

export default ShortenerLinksDashboard
