// CreateProjectType.tsx
import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import LoadingIcon from "@/components/loading/loading-icon";
import {
  stages,
  departments,
  projectTypes,
} from "@/services/project-service";
import { useQuery } from "@tanstack/react-query";
import { formTemplates } from "@/services/forms-service";
import TaskStagesForm from "@/components/project-type-components/TaskStagesForm";
import InputField from "@/components/project-type-components/InputField";


const CreateProjectType = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [formError, setFormError] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [type, setType] = useState<any>({
    title: "",
    description: "",
    task_stages: [],
  });
  const [fetchedTaskTemplates, setFetchedTaskTemplates] = useState<any>();
  const [fetchedProjectStages, setFetchedProjectStages] = useState();
  const [fetchedDepartments, setFetchedDepartments] = useState<any>();
  const [isFetchingDepartments, setIsFetchingDepartments] = useState(false);
  const [taskTemplatesType, setTaskTemplatesType] = useState([]);
  const [taskTemplatesAssignedGroup, setTaskTemplatesAssignedGroup] = useState([]);
  const [taskTemplatesGroupRole, setTaskTemplatesGroupRole] = useState([]);
  const [fetchedFormTemplates, setFetchedFormTemplates] = useState<any>();
  const [isFetchingFormTemplates, setIsFetchingFormTemplates] = useState(false);

  useEffect(() => {
    document.title = "Lazem GO - Create Project Type";
  }, [fetchedProjectStages, fetchedDepartments]);

  const fetchDepartments = async () => {
    setIsFetchingDepartments(true);
    await departments.list()
      .then((response: any) => {
        let departments: any = [];
        for (let i = 0; i < response.data.length; i++) {
          departments.push({
            value: response.data[i].id,
            display_name: response.data[i].title,
            roles: response.data[i].roles.map((role: any) => {
              return {
                value: role.id,
                display_name: role.title + " ( " + (role?.code || "") + " )",
              };
            }
            ),
          });
        }
        setFetchedDepartments(departments);
      })
      .catch((error) => {
        console.log("Error", error);
      })
      .finally(() => {
        setIsFetchingDepartments(false);
      });
  };

  const fetchStages = async () => {
    await stages.list()
      .then((response: any) => {
        let stages: any = [];
        for (let i = 0; i < response.data.length; i++) {
          stages.push({
            value: response.data[i].id,
            display_name: response.data[i].title,
          });
        }
        setFetchedProjectStages(stages);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const fetchTaskTemplates = async () => {
    await projectTypes.list_task_templates()
      .then((response: any) => {
        setFetchedTaskTemplates(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const fetchFormTemplates = async () => {
    setIsFetchingFormTemplates(true);
    await formTemplates
      .list()
      .then((response:any) => {
        let forms: any = [];
        for (let i = 0; i < response.data.length; i++) {
          forms.push({
            value: response.data[i].id,
            display_name: response.data[i].title,
          });
        }
        setFetchedFormTemplates(forms);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsFetchingFormTemplates(false);
      });
  };

  const fetchOptions = async () => {
    await projectTypes.options()
      .then((response: any) => {
        let task_types = response?.data?.actions?.POST?.task_stages?.child?.children?.task_templates?.child?.children?.type?.choices;
        let group_role = response?.data?.actions?.POST?.task_stages?.child?.children?.task_templates?.child?.children?.group_role?.choices;
        let assigned_group = response?.data?.actions?.POST?.task_stages?.child?.children?.task_templates?.child?.children?.assigned_group?.choices;
        setTaskTemplatesType(task_types);
        setTaskTemplatesGroupRole(group_role);
        setTaskTemplatesAssignedGroup(assigned_group);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getData = async () => {
    if (!fetchedProjectStages) {
      await fetchOptions();
      await fetchStages();
      await fetchDepartments();
      await fetchTaskTemplates();
      await fetchFormTemplates(); // Fetch form templates here as well to initialize
    }
  };

  const { isLoading: stagesLoading } = useQuery({
    queryKey: ["create project type"],
    queryFn: getData,
    refetchOnWindowFocus: false,
  });

  const handleChange = (
    e: any,
    table: any,
    taskStageIndex = null,
    templateIndex = null
  ) => {
    const { name, value } = e.target;

    if (table === "type") {
      setType({
        ...type,
        [name]: value,
      });
    } else if (table === "taskStages" && taskStageIndex !== null) {
      const updatedTaskStages = type.task_stages.map(
        (taskStage: any, index: any) => {
          if (index === taskStageIndex) {
            return {
              ...taskStage,
              [name]: value,
            };
          }
          return taskStage;
        }
      );

      setType({
        ...type,
        task_stages: updatedTaskStages,
      });
    } else if (
      table === "taskTemplates" &&
      taskStageIndex !== null &&
      templateIndex !== null
    ) {
      const updatedTaskStages = type.task_stages.map(
        (taskStage: any, index: any) => {
          if (index === taskStageIndex) {
            const updatedTaskTemplates = taskStage.task_templates.map(
              (template: any, i: any) => {
                if (i === templateIndex) {
                  return {
                    ...template,
                    [name]: value,
                  };
                }
                return template;
              }
            );

            return {
              ...taskStage,
              task_templates: updatedTaskTemplates,
            };
          }
          return taskStage;
        }
      );

      setType({
        ...type,
        task_stages: updatedTaskStages,
      });
    }
  };

  const handleAddTaskStage = () => {
    let newTaskStage = {
      title: "",
      description: "",
      stage: "",
      task_templates: [],
      index: type.task_stages.length + 1
    };
    setType((prevType: any) => ({
      ...prevType,
      task_stages: [...prevType.task_stages, newTaskStage],
    }));
  };

  const handleAddTaskTemplate = (taskStageIndex: any) => {
    const newTaskTemplate = {
      title: "",
      description: "",
      type: null,
      assigned_group: null,
      duration_minutes: null,
      start_time: null,
      group_role: null,
      department: null,
    };

    const updatedTaskStages = type.task_stages.map(
      (taskStage: any, index: any) => {
        if (index === taskStageIndex) {
          return {
            ...taskStage,
            task_templates: [...taskStage.task_templates, newTaskTemplate],
          };
        }
        return taskStage;
      }
    );

    setType({
      ...type,
      task_stages: updatedTaskStages,
    });
  };

  const handleRemoveTaskStage = (index: any) => {
    const updatedTaskStages = type.task_stages.filter(
      (_: any, i: any) => i !== index
    );
    setType({
      ...type,
      task_stages: updatedTaskStages,
    });
  };

  const handleRemoveTaskTemplate = (
    taskStageIndex: any,
    templateIndex: any
  ) => {
    const updatedTaskStages = type.task_stages.map((taskStage: any, i: any) => {
      if (i === taskStageIndex) {
        const updatedTaskTemplates = taskStage.task_templates.filter(
          (_: any, j: any) => j !== templateIndex
        );
        return {
          ...taskStage,
          task_templates: updatedTaskTemplates,
        };
      }
      return taskStage;
    });

    setType({
      ...type,
      task_stages: updatedTaskStages,
    });
  };

  const validateForm = () => {
    let isValid = true;
    let errors = [];

    if (!type.title) {
      errors.push(
        language === "en"
          ? "Project title is required."
          : "عنوان المشروع مطلوب."
      );
      isValid = false;
    }

    if (!type.description) {
      errors.push(
        language === "en"
          ? "Project description is required."
          : "وصف المشروع مطلوب."
      );
      isValid = false;
    }

    if (type.task_stages.length === 0) {
      errors.push(
        language === "en"
          ? "At least one task stage is required."
          : "مطلوب مرحلة واحدة على الأقل."
      );
      isValid = false;
    } else {
      type.task_stages.forEach((taskStage: any, taskStageIndex: any) => {
        if (!taskStage.title) {
          errors.push(
            `${language === "en"
              ? "Task stage title is required for task stage"
              : "عنوان مرحلة المهمة مطلوب لمرحلة المهمة"
            } ${taskStageIndex + 1}.`
          );
          isValid = false;
        }
        if (!taskStage.stage) {
          errors.push(
            `${language === "en"
              ? "Stage is required for task stage"
              : "المرحلة مطلوبة لمرحلة المهمة"
            } ${taskStageIndex + 1}.`
          );
          isValid = false;
        }
        if (taskStage.task_templates.length === 0) {
          errors.push(
            `${language === "en"
              ? "At least one task template is required for task stage"
              : "مطلوب قالب مهمة واحد على الأقل لمرحلة المهمة"
            } ${taskStageIndex + 1}.`
          );
          isValid = false;
        } else {
          if (taskStage.task_templates.length > 0) {
            taskStage.task_templates.forEach(
              (taskTemplate: any, templateIndex: any) => {
                if (!taskTemplate.title) {
                  errors.push(
                    `${language === "en"
                      ? "Task template title is required for task stage"
                      : "عنوان قالب المهمة مطلوب لمرحلة المهمة"
                    } ${taskStageIndex + 1}, ${language === "en" ? "template" : "القالب"
                    } ${templateIndex + 1}.`
                  );
                  isValid = false;
                } if (taskTemplate.assigned_group === "department" && !taskTemplate.department) {
                  errors.push(
                    `${language === "en"
                      ? "Department is required for task stage"
                      : "القسم مطلوب لمرحلة المهمة"
                    } ${taskStageIndex + 1}, ${language === "en" ? "template" : "القالب"
                    } ${templateIndex + 1}.`
                  );
                  isValid = false;
                }
                if (taskTemplate.group_role === null || taskTemplate.group_role === "") {
                  errors.push(
                    `${language === "en"
                      ? "Group Role is required for task stage"
                      : "دور المجموعة مطلوب لمرحلة المهمة"
                    } ${taskStageIndex + 1}, ${language === "en" ? "template" : "القالب"
                    } ${templateIndex + 1}.`
                  );
                  isValid = false;
                }
                if (taskTemplate.type === null || taskTemplate.type === "") {
                  errors.push(
                    `${language === "en"
                      ? "Type is required for task stage"
                      : "النوع مطلوب لمرحلة المهمة"
                    } ${taskStageIndex + 1}, ${language === "en" ? "template" : "القالب"
                    } ${templateIndex + 1}.`
                  );
                  isValid = false;
                }
              }
            );
          }
        }
      });
    }

    setFormError(errors.join("\n"));
    return isValid;
  };

  const handleSave = () => {
    if (validateForm()) {
      setFormError("");
      setFormLoading(true);
      projectTypes.create(type)
        .then((response) => {
          setFormLoading(false);
          navigate("/projects/settings");
        })
        .catch((error) => {
          console.log("Error", error);
          setFormError(language === "en" ? "Failed to save project type." : "فشل حفظ نوع المشروع.");
        })
        .finally(() => {
          setFormLoading(false);
        });
    }
  };

  const functionToCall = (field: string) => {
    if (field === "department") {
      fetchDepartments();
    } else if (field === "stage") {
      fetchStages();
    } else if (field === "form_template") {
      fetchFormTemplates();
    }
  };


  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {stagesLoading && <LoadingScreen />}

      {fetchedProjectStages && fetchedDepartments && (
        <Card className="max-w-3xl mx-auto my-8 ">
          <CardHeader>
            <Button
              onClick={() => navigate("/projects/settings")}
              className="w-full my-4"
              variant={"secondary"}
            >
              {language === "en" ? "Back" : "عودة"}
            </Button>
            <CardTitle className="text-xl font-bold">
              {language === "en" ? "Create Project Type" : "إنشاء نوع المشروع"}
            </CardTitle>
          </CardHeader>
          <CardContent className="text-start">
            <div className="flex flex-col gap-4">
              <InputField
                label={language === "en" ? "Title" : "العنوان"}
                field="title"
                value={type.title}
                table="type"
                onChange={handleChange}
              />
              <InputField
                label={language === "en" ? "Description" : "الوصف"}
                field="description"
                value={type.description}
                table="type"
                onChange={handleChange}
              />

              <TaskStagesForm
                type={type}
                onChange={handleChange}
                onRemoveTaskStage={handleRemoveTaskStage}
                onAddTaskStage={handleAddTaskStage}
                onAddTaskTemplate={handleAddTaskTemplate}
                onRemoveTaskTemplate={handleRemoveTaskTemplate}
                fetchedProjectStages={fetchedProjectStages}
                fetchedDepartments={fetchedDepartments}
                fetchedTaskTemplates={fetchedTaskTemplates}
                taskTemplatesType={taskTemplatesType}
                taskTemplatesAssignedGroup={taskTemplatesAssignedGroup}
                taskTemplatesGroupRole={taskTemplatesGroupRole}
                fetchedFormTemplates={fetchedFormTemplates}
                isFetchingFormTemplates={isFetchingFormTemplates}
                isFetchingDepartments={isFetchingDepartments}
                functionToCall={functionToCall}
              />


              {formError && (
                <div>
                  {formError.split("\n").map((error: any) => (
                    <div className="text-red-500 text-sm my-2 font-bold ">
                      {error}
                    </div>
                  ))}
                </div>
              )}
              <Button onClick={handleSave} disabled={formLoading}>
                {formLoading ? (
                  <LoadingIcon />
                ) : language === "en" ? (
                  "Save"
                ) : (
                  "حفظ"
                )}
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default CreateProjectType;