// InputField.tsx
import React from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface InputFieldProps {
  label: string;
  field: string;
  value: any;
  table: string;
  type?: "text" | "number" | "time";
  taskStageIndex?: number | null;
  templateIndex?: number | null;
  onChange: (e: any, table: string, taskStageIndex?: number | any, templateIndex?: number | any) => void;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  field,
  value,
  table,
  type = "text",
  taskStageIndex = null,
  templateIndex = null,
  onChange,
}) => {
  return (
    <div className="flex flex-col my-4">
      <Label className="mb-1">{label}</Label>
      <Input
        type={type}
        value={value}
        name={field}
        onChange={(e) => onChange(e, table, taskStageIndex, templateIndex)}
      />
    </div>
  );
};

export default InputField;