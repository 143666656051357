// SelectField.tsx
import React from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { FiRefreshCw } from "react-icons/fi";
import LoadingIcon from "@/components/loading/loading-icon";
import { useLanguage } from "@/components/context/LanguageContext";

interface SelectFieldProps {
  label: string;
  field: string;
  value: any;
  options: any;
  table: string;
  taskStageIndex?: number | null;
  templateIndex?: number | null;
  onChange: (e: any, table: string, taskStageIndex?: number | null, templateIndex?: number | null) => void;
  withAdd?: boolean;
  url?: string;
  permission?: string;
  functionToCall?: (field: string) => void;
  isLoadingOptions?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  field,
  value,
  options,
  table,
  taskStageIndex = null,
  templateIndex = null,
  onChange,
  withAdd = false,
  url = "",
  permission,
  functionToCall,
  isLoadingOptions = false,
}) => {
  const { language } = useLanguage();

  if (isLoadingOptions || !options) {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-1">{label}</Label>
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div className="flex flex-col my-6">
      <Label className="mb-1">{label}</Label>
      <div className="flex gap-2 w-full">
        <div className={`${withAdd ? "w-4/5 " : "w-full"}`}>
          <Select
            onValueChange={(value) =>
              onChange(
                { target: { name: field, value: value } },
                table,
                taskStageIndex,
                templateIndex
              )
            }
            value={value}
            name={field}
            dir={language === "en" ? "ltr" : "rtl"}
          >
            <SelectTrigger>
              <SelectValue
                placeholder={
                  language === "en" ? `Select ${label}` : `اختر ${label}`
                }
              />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>
                  {language === "en" ? `Select ${label}` : `اختر ${label}`}
                </SelectLabel>
                {options?.map((option: any, index: any) => (
                  <SelectItem key={index} value={option.value}>
                    {option.display_name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        {withAdd && functionToCall && (
          <div>
            <Button
              variant="outline"
              onClick={() => functionToCall(field || "")}
              permission={permission}
            >
              <FiRefreshCw />
            </Button>
          </div>
        )}
      </div>
      {withAdd && url && (
        <Button
          variant="link"
          className="text-sm flex justify-start w-fit"
          onClick={() => window.open(url, "_blank")}
          permission={permission}
        >
          {language === "en" ? "Add New" : "إضافة جديد"}
        </Button>
      )}
    </div>
  );
};

export default SelectField;