import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import LogFeed from "@/components/log-feed";
import LoadingIcon from "@/components/loading/loading-icon";
import { Button } from "@/components/ui/button";
import html2canvas from "html2canvas";
import { business_cards, business_card_logs } from "@/services/user-service";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { is_arabic, is_english } from "@/services/language-checker";
import { categories, tickets } from "@/services/tickets-services";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { GiCheckMark } from "react-icons/gi";

const BusinessCardView = () => {
    const { language } = useLanguage();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [card, setCard] = useState<any>();
    const [isNotifySuccess, setIsNotifySuccess] = useState(false);
    const [cardStatusList] = useState<any>([
        { id: "need_update", display_name: "Need Update", display_name_ar: "تحتاج تحديث", progress: 0, complete_display_name: "Updated", complete_display_name_ar: "تم التحديث" },
        { id: "ready", display_name: "Ready for printing", display_name_ar: "جاهزة للطباعة", progress: 25, complete_display_name: "Ready to print", complete_display_name_ar: "جاهزة للطباعة" },
        { id: "requested", display_name: "Request sent", display_name_ar: "تم إرسال طلب", progress: 50, complete_display_name: "Requested", complete_display_name_ar: "تم الطلب" },
        { id: "printed", display_name: "Waiting for print", display_name_ar: "في انتظار الطباعة", progress: 75, complete_display_name: "Printed", complete_display_name_ar: "تمت الطباعة" },
        { id: "delivered", display_name: "Waiting for delivery", display_name_ar: "في انتظار التسليم", progress: 100, complete_display_name: "Delivered", complete_display_name_ar: "تم التسليم" },
        // { id: "expired", display_name: "Expired", display_name_ar: "منتهية", progress: -1 },
    ]);
    const [employeeDetail] = useState<any>({
        title: "Business Card",
        support_type: "bemployee_business_card",
        description: "Request Employee Business Card for",
        priority: "High",
        json_request: [
        ],
        category: null,
    });

    const {
        data: cardsData,
        isLoading: cardsLoading,
        isFetching: cardsFetching,
        refetch: refetchCards,
    }   = useQuery({
        queryKey: ["Business cards view", id],
        queryFn: () => business_cards.get(id).then((res:any) => {
            document.title = res?.data?.user?.full_name + " - Card ";
            setCard(res?.data);
            return res?.data;
        }),
        refetchOnWindowFocus: false,
    });
    const contentRef = useRef<any>();


    const handlePrint = async () => {
        setLoading(true);
        setTimeout(async () => {
            const targetDiv = document.getElementById("component-to-print");
            if (targetDiv) {
                try {
                    const canvas = await html2canvas(targetDiv, {
                        scrollX: 0,
                        scrollY: -window.scrollY,
                        useCORS: true,
                        allowTaint: true,

                    });
                    const imageData = canvas.toDataURL("image/png");
                    const downloadLink = document.createElement("a");
                    downloadLink.href = imageData;
                    downloadLink.download = cardsData?.user?.full_name + " card.png";
                    downloadLink.click();
                } catch (error) {
                    console.error("Error converting div to image:", error);
                }
            } else {
                console.error("Target div not found");
            }
            setLoading(false);
        }, 1000);
    };

    const handleStatusChange = (selectedStatus: any) => {
        setLoading(true);
        let card_log_data = {
            card: id,
            message: "Status changed to " + selectedStatus + "\n " + "تم تغيير الحالة الى " + selectedStatus,
            new_status: selectedStatus,
        }
        business_card_logs.create(card_log_data).then(async (res) => {
            await refetchCards();
            setLoading(false);
        }).catch((error) => {
            console.log("Error", error);
        })
    }

    const fillTheForm = () => {
        setLoading(true);
        const employeeDetailCopy = { ...employeeDetail };
        employeeDetailCopy.title = "Employee Business Card for " + cardsData?.user?.full_name;
        employeeDetailCopy.category = categoryData?.data[0]?.id;
        employeeDetailCopy.service_type = "print_employee_business_card";
        employeeDetailCopy.json_request = [
            {
                question_en: "Employee full name EN",
                question_ar: "اسم الموظف بالانجليزية",
                answer: cardsData?.user?.full_name,
                is_required: true,
                name: "employee_full_name_en",
            },
            {
                question_en: "Employee full name AR",
                question_ar: "اسم الموظف بالعربية",
                answer: cardsData?.user?.full_name_ar,
                is_required: true,
                name: "employee_full_name_ar",
            },
            {
                question_en: "Employee ID",
                question_ar: "الرقم الوظيفي",
                answer: cardsData?.employee_id,
                is_required: true,
                name: "employee_id",
            },
            {
                question_en: "Employee Position EN",
                question_ar: "منصب الموظف بالانجليزية",
                answer: cardsData?.role,
                is_required: true,
                name: "employee_position",
            },
            {
                question_en: "Employee Position AR",
                question_ar: "منصب الموظف بالعربية",
                answer: cardsData?.role_ar,
                is_required: true,
                name: "employee_position_ar",
            },
            {
                question_en: "Employee Email",
                question_ar: "بريد الموظف",
                answer: cardsData?.user?.email,
                is_required: true,
                name: "employee_email",
            },
        ];
        tickets.create(employeeDetailCopy).then((response:any) => {
            let card_log_data = {
                card: id,
                message: "Print request has been created Ticket ID: " + response?.data?.id + "\n " + "تم انشاء طلب الطباعة برقم الطلب: " + response?.data?.id,
                new_status: "requested",
            }
            business_card_logs.create(card_log_data).then(async (res) => {
                await refetchCards();
                setLoading(false);

            }).catch((error) => {
                console.log("Error", error);
            })
        }).catch((error) => {
            console.log("Error", error);
        });
    }

    const handleNotifyTheUser = () => {
        setLoading(true);
        business_cards.notify_employee(card?.id).then(async () => {
            // TODO: Success message
            setIsNotifySuccess(true);
            await refetchCards();
            setLoading(false);
        }).catch((error) => {
            console.log("Error", error);
            // TODO: Error handling
            setLoading(false);
            setIsNotifySuccess(false);
        });
    }
    const isReady = () => {
        return card?.status === "ready";
    }
    const {
        data: categoryData,
        isLoading: categoryLoading,
    }:any = useQuery({
        queryKey: ["category open ticket"],
        queryFn: async () => categories.getWithFilter("tag=print_business_card"),
        refetchOnWindowFocus: false,
        enabled: isReady()
    });

    return (
        <div
            dir={language === "en" ? "ltr" : "rtl"}
            className="flex flex-col"
        >
            {(cardsLoading || cardsFetching) && <LoadingScreen />}

            {cardsData &&
                <>
                    <Card
                        className="mt-8 w-full md:w-4/5 lg:w-3/5 mx-auto"
                    >
                        <CardHeader>
                            <Button
                                onClick={() => navigate("/organization/business-cards")}
                                variant="secondary"
                                className="my-4"
                            >
                                {language === "en" ? "Back" : "رجوع"}
                            </Button>
                            <CardTitle>
                                {cardsData?.user?.full_name}
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="flex flex-col md:flex-row gap-4">
                                <div
                                    className="grid grid-cols-1 md:grid-cols-2 gap-4 text-start w-full"
                                >
                                    <div>
                                        <p>
                                            {language === "en" ? "Full Name EN" : "الاسم الكامل بالانجليزي"}:
                                        </p>
                                        <p>
                                            {cardsData?.user?.full_name ? cardsData?.user?.full_name : language === "en" ? "N/A" : "غير متوفر"}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {language === "en" ? "Full Name AR" : "الاسم الكامل بالعربي"}:
                                        </p>
                                        <p>
                                            {cardsData?.user?.full_name_ar ? cardsData?.user?.full_name_ar : language === "en" ? "N/A" : "غير متوفر"}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {language === "en" ? "Role EN" : "الدور بالانجليزي"}:
                                        </p>
                                        <p>
                                            {cardsData?.role ? cardsData?.role : language === "en" ? "N/A" : "غير متوفر"}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {language === "en" ? "Role AR" : "الدور بالعربي"}:
                                        </p>
                                        <p>
                                            {cardsData?.role_ar ? cardsData?.role_ar : language === "en" ? "N/A" : "غير متوفر"}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {language === "en" ? "Phone Number" : "رقم الجوال"}:
                                        </p>
                                        <p>
                                            {cardsData?.phone_number ? cardsData?.phone_number : language === "en" ? "N/A" : "غير متوفر"}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {language === "en" ? "Landline" : "رقم الهاتف"}:
                                        </p>
                                        <p>
                                            {cardsData?.landline ? cardsData?.landline : language === "en" ? "N/A" : "غير متوفر"}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {language === "en" ? "Landline Extension" : "تحويلة الهاتف"}:
                                        </p>
                                        <p>
                                            {cardsData?.landline_extension ? cardsData?.landline_extension : language === "en" ? "N/A" : "غير متوفر"}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {language === "en" ? "Email" : "البريد الالكتروني"}:
                                        </p>
                                        <p>
                                            {cardsData?.user?.email ? cardsData?.user?.email : language === "en" ? "N/A" : "غير متوفر"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card
                        dir={language === "en" ? "ltr" : "rtl"}
                        className="mt-8 w-full md:w-4/5 lg:w-3/5 mx-auto"
                    >

                        {
                            card?.status === "ready" &&
                            <div
                                className="p-4 flex flex-col gap-4 w-full md:w-4/5 lg:w-3/5 mx-auto"
                            >
                                <p
                                    className="text-center w-full font-bold text-lg"
                                >
                                    {/* {language === "en" ? "The card is ready. Please download the card demo to check the card before printing" : "البطاقة جاهزة. يرجى تنزيل العرض التجريبي للبطاقة للتحقق منها قبل الطباعة"} */}
                                </p>
                                {/* <Button
                                    onClick={() => handlePrint()}
                                    disabled={loading || card?.status !== "ready"}
                                    variant={"secondary"}
                                >
                                    {language === "en" ? "View demo before printing" : "عرض تجريبي قبل الطباعة"}
                                    {loading && <LoadingIcon />}
                                </Button> */}
                                <Button
                                    onClick={() => fillTheForm()}
                                    disabled={loading || card?.status !== "ready"}
                                >
                                    {language === "en" ? "Print Request" : "طلب الطباعة"}
                                    {loading && <LoadingIcon />}
                                </Button>
                            </div>
                        }
                        <div className="flex justify-center gap-4 mt-6 flex-col">
                            {
                                card?.status === "requested" &&
                                <>
                                    <p
                                        className="text-center w-full font-bold text-lg"
                                    >
                                        {language === "en" ? "The card is requested, and waiting for the card to be printed. Please check the logs for more information" : "تم طلب البطاقة وفي انتظار طباعة البطاقة. يرجى التحقق من السجلات لمزيد من المعلومات"}
                                    </p>

                                    <Button
                                        onClick={() => handleStatusChange("printed")}
                                        disabled={loading}
                                        className="w-fit mx-4"
                                    >
                                        {language === "en" ? "Click here if the card is printed" : "انقر هنا إذا تمت الطباعة"}
                                        {loading && <LoadingIcon />}
                                    </Button>
                                </>
                            }
                            {
                                card?.status === "printed" &&
                                <>
                                    <p
                                        className="text-center w-full font-bold text-lg"
                                    >
                                        {language === "en" ? "Please hand over the card to the employee" : "يرجى تسليم البطاقة للموظف"}
                                    </p>
                                    <Button
                                        onClick={() => handleStatusChange("delivered")}
                                        disabled={loading}
                                        className="w-fit mx-4"
                                    >
                                        {language === "en" ? "Click here if the card is delivered" : "انقر هنا إذا تم تسليم البطاقة"}
                                        {loading && <LoadingIcon />}
                                    </Button>
                                </>
                            }
                            {
                                card?.status === "delivered" &&
                                <>
                                    <p
                                        className="text-center w-full font-bold text-lg"
                                    >
                                        {language === "en" ? "The card has been delivered" : "تم تسليم البطاقة"}
                                    </p>
                                </>
                            }
                            {
                                card?.status === "expired" &&
                                <p
                                    className="text-red-500 text-center w-full font-bold "
                                >
                                    {language === "en" ? "The card is expired" : "انتهت صلاحية البطاقة"}
                                </p>
                            }
                        </div>
                        <Breadcrumb
                            className="mt-4 w-full m-auto flex justify-center p-4"
                        >
                            <BreadcrumbList
                            >
                                {cardStatusList.map((status: any, index: any) => {
                                    const currentIndex = cardStatusList.findIndex((s: any) => s.id === card?.status);
                                    const isCompleted = index < currentIndex;
                                    const isCurrent = index === currentIndex;
                                    const isNext = index === currentIndex + 1;
                                    const isNeedUpdate = card?.status === "need_update" || card?.status === "ready";
                                    const statusName = language === "en" ? status.display_name : status.display_name_ar;

                                    return (
                                        <>
                                            <BreadcrumbItem key={status.id}>
                                                {((isCompleted || isCurrent) && !isNeedUpdate) && <GiCheckMark className="text-green-500" />}
                                                <BreadcrumbLink
                                                    className={`
                                                        ${isCompleted ? "text-green-500" : ""} 
                                                        ${isCurrent && isNeedUpdate ? "font-bold text-yellow-500 animate-pulse" : isCurrent ? "font-bold text-green-500" : ""} 
                                                        ${isNext && !isNeedUpdate ? "font-bold text-yellow-500 animate-pulse" : ""}
                                                        ${card?.status === "delivered" ? "text-green-500 animate-none" : ""}
                                                    `}
                                                >
                                                    {statusName}
                                                </BreadcrumbLink>
                                            </BreadcrumbItem>
                                            {index < cardStatusList.length - 1 && (
                                                <BreadcrumbSeparator dir={language === "en" ? "ltr" : "rtl"} />
                                            )}
                                        </>
                                    );
                                })}
                            </BreadcrumbList>
                        </Breadcrumb>


                        {card?.status === "need_update" &&
                            <>
                                <CardHeader>
                                    <CardTitle>
                                        {language === "en" ? "The following information needs to be updated before printing the card" : "المعلومات التالية تحتاج الى تحديث قبل طباعة البطاقة"}
                                    </CardTitle>
                                </CardHeader>
                                <CardContent
                                    className="text-start"
                                >
                                    <CardDescription>
                                        {
                                            (!is_english(card?.user?.full_name)) &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "English full name" : "الاسم الكامل بالانجليزي"}
                                            </p>
                                        }
                                        {
                                            (!is_arabic(card?.user?.full_name_ar)) &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "Add full name in Arabic" : "اضافة الاسم الكامل بالعربي"}
                                            </p>
                                        }
                                        {
                                            !card?.role_ar &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "Add role in Arabic" : "اضافة الدور بالعربي"}
                                            </p>
                                        }
                                        {
                                            !card?.role &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "Add role in English" : "اضافة الدور بالانجليزي"}
                                            </p>
                                        }
                                        {
                                            !card?.phone_number &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "Add phone number" : "اضافة رقم الجوال"}
                                            </p>
                                        }
                                        {
                                            !card?.landline &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "Add landline number" : "اضافة رقم الهاتف"}
                                            </p>
                                        }
                                    </CardDescription>
                                </CardContent>

                                {!isNotifySuccess &&
                                    <Button
                                        onClick={handleNotifyTheUser}
                                        className="w-fit my-4"
                                        disabled={loading}
                                    >
                                        {language === "en" ? "Notify Employee" : "إخطار الموظف"}
                                        {loading && <LoadingIcon />}
                                    </Button>
                                }
                                {isNotifySuccess &&
                                    <p
                                        className="text-green-500 text-center w-full font-bold text-xl"
                                    >
                                        {language === "en" ? "An email has been sent to the employee" : "تم ارسال بريد الكتروني للموظف"}
                                    </p>
                                }
                            </>
                        }
                        <LogFeed logs={card?.business_card_logs} />
                    </Card>
                </>
            }

        </div>
    );
};

export default BusinessCardView;