import * as React from "react";
import { CheckIcon, ChevronDown, XIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";

interface SelectWithSearchProps {
  options: { label: string; value: string }[];
  onValueChange: (value: string) => void;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  variant?: "default" | "secondary" | "link" | "destructive" | "outline" | "ghost" | null | undefined;
}

export const SelectWithSearch = React.forwardRef<HTMLButtonElement, SelectWithSearchProps>(
  ({ options, onValueChange, defaultValue = "", placeholder = "Select an option", className,disabled,variant, ...props }, ref) => {
    const [selectedValue, setSelectedValue] = React.useState<string>(defaultValue);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    const handleSelect = (value: string) => {
      setSelectedValue(value);
      onValueChange(value);
      setIsPopoverOpen(false);
    };

    return (
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <Button ref={ref} {...props} onClick={() => setIsPopoverOpen((prev) => !prev)} disabled={disabled} variant={variant}
            className={cn("flex w-full p-2 rounded-md border items-center justify-between", className)}>
            {selectedValue ? (
              <span>{options.find((o) => o.value === selectedValue)?.label}</span>
            ) : (
              <span className="text-muted-foreground">{placeholder}</span>
            )}
            <ChevronDown className="h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Command>
            <CommandInput placeholder="Search..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem key={option.value} onSelect={() => handleSelect(option.value)} className="cursor-pointer">
                    <div className={cn("mr-2 flex h-4 w-4 items-center justify-center rounded-sm border", 
                      selectedValue === option.value ? "bg-primary text-primary-foreground" : "opacity-50")}
                    >
                      {selectedValue === option.value && <CheckIcon className="h-4 w-4" />}
                    </div>
                    <span>{option.label}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);

SelectWithSearch.displayName = "SelectWithSearch";
