import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import LoadingIcon from "@/components/loading/loading-icon";
import {
  stages,
  departments,
  projectTypes,
} from "@/services/project-service";
import { FiRefreshCw } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import { formTemplates } from "@/services/forms-service";
import { Checkbox } from "@/components/ui/checkbox";
import { MultiSelect } from "@/components/ui/multi-select";
import { useAuth } from "@/components/context/AuthContext";
import { roles } from "@/services/user-service";

const EditProjectType = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [formError, setFormError] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [type, setType] = useState<any>({
    title: "",
    description: "",
    task_stages: [],
  });
  const [fetchedTaskTemplates, setFetchedTaskTemplates] = useState<any>();
  const [isCreateTaskTemplate, setIsCreateTaskTemplate] = useState(false);
  const [fetchedProjectStages, setFetchedProjectStages] = useState();
  const [fetchedDepartments, setFetchedDepartments] = useState<any>();
  const [isFetchingDepartments, setIsFetchingDepartments] = useState(false);
  const [taskTemplatesType, setTaskTemplatesType] = useState([]);
  const [taskTemplatesAssignedGroup, setTaskTemplatesAssignedGroup] = useState([]);
  const [taskTemplatesGroupRole, setTaskTemplatesGroupRole] = useState([]);
  const [fetchedFormTemplates, setFetchedFormTemplates] = useState<any>();
  const [isFetchingFormTemplates, setIsFetchingFormTemplates] = useState(false);
  const { checkPermission } = useAuth();
  const canEditProjectTypes = checkPermission("change_projecttypes");

  useEffect(() => {
    document.title = "Lazem GO - Edit Project Type";
  }, []);

  const fetchDepartments = async () => {
    setIsFetchingDepartments(true);
    await departments.list()
      .then((response: any) => {
        let departments: any = [];
        for (let i = 0; i < response.data.length; i++) {
          departments.push({
            value: response.data[i].id,
            display_name: response.data[i].title,
            roles: response.data[i].roles.map((role: any) => {
              return {
                value: role.id,
                display_name: role.title + " ( " + (role?.code || "") + " )",
              };
            }
            ),
          });
        }
        setFetchedDepartments(departments);
      })
      .catch((error) => {
        console.log("Error", error);
      })
      .finally(() => {
        setIsFetchingDepartments(false);
      });
  };

  const getPrjectTypeDetails = () => {
    projectTypes
      .get(id)
      .then(async (response:any) => {
        setType(response.data);
        await fetchDepartments();
        await fetchStages();
        await fetchTaskTemplates();
        await fetchFormTemplates();
        await fetchOptions();
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const { isLoading: departmentsLoading } = useQuery({
    queryKey: ["Edit project type"],
    queryFn: getPrjectTypeDetails,
    refetchOnWindowFocus: false,
  });

  const dependentTasksOptions = (taskStageIndex: any, tasks: any) => {
    let options: any = [];
    for (let i = 0; i < taskStageIndex; i++) {
      tasks[i].task_templates.map((template: any) => {
        options.push({
          value: template.id,
          label: template.title,
        });
      });
    }
    return options;
  };

  const fetchStages = async () => {
    await stages.list()
      .then((response: any) => {
        let stages: any = [];
        for (let i = 0; i < response.data.length; i++) {
          stages.push({
            value: response.data[i].id,
            display_name: response.data[i].title,
          });
        }
        setFetchedProjectStages(stages);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const fetchTaskTemplates = async () => {
    await projectTypes.list_task_templates()
      .then((response: any) => {
        setFetchedTaskTemplates(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const fetchFormTemplates = async () => {
    setIsFetchingFormTemplates(true);
    await formTemplates
      .list()
      .then((response:any) => {
        let forms: any = [];
        for (let i = 0; i < response.data.length; i++) {
          forms.push({
            value: response.data[i].id,
            display_name: response.data[i].title,
          });
        }
        setFetchedFormTemplates(forms);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsFetchingFormTemplates(false);
      });
  };

  const fetchOptions = async () => {
    await projectTypes.options()
      .then((response: any) => {
        let task_types = response?.data?.actions?.POST?.task_stages?.child?.children?.task_templates?.child?.children?.type?.choices;
        let group_role = response?.data?.actions?.POST?.task_stages?.child?.children?.task_templates?.child?.children?.group_role?.choices;
        let assigned_group = response?.data?.actions?.POST?.task_stages?.child?.children?.task_templates?.child?.children?.assigned_group?.choices;
        setTaskTemplatesType(task_types);
        setTaskTemplatesGroupRole(group_role);
        setTaskTemplatesAssignedGroup(assigned_group);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const handleChange = (
    e: any,
    table: any,
    taskStageIndex = null,
    templateIndex = null
  ) => {
    const { name, value } = e.target;
    if (table === "type") {
      setType({
        ...type,
        [name]: value,
      });
    } else if (table === "taskStages" && taskStageIndex !== null) {
      const updatedTaskStages = type.task_stages.map(
        (taskStage: any, index: any) => {
          if (index === taskStageIndex) {
            return {
              ...taskStage,
              [name]: value,
            };
          }
          return taskStage;
        }
      );

      setType({
        ...type,
        task_stages: updatedTaskStages,
      });
    } else if (
      table === "taskTemplates" &&
      taskStageIndex !== null &&
      templateIndex !== null
    ) {
      const updatedTaskStages = type.task_stages.map(
        (taskStage: any, index: any) => {
          if (index === taskStageIndex) {
            const updatedTaskTemplates = taskStage.task_templates.map(
              (template: any, i: any) => {
                if (i === templateIndex) {
                  return {
                    ...template,
                    [name]: value,
                  };
                }
                return template;
              }
            );

            return {
              ...taskStage,
              task_templates: updatedTaskTemplates,
            };
          }
          return taskStage;
        }
      );

      setType({
        ...type,
        task_stages: updatedTaskStages,
      });
    }
  };

  const handleAddTaskStage = () => {
    let newTaskStage = {
      title: "",
      description: "",
      stage: "",
      task_templates: [],
      index: type.task_stages.length + 1
    };
    setType((prevType: any) => ({
      ...prevType,
      task_stages: [...prevType.task_stages, newTaskStage],
    }));
  };

  const handleAddTaskTemplate = (taskStageIndex: any) => {
    const newTaskTemplate = {
      title: "",
      description: "",
      type: null,
      assigned_group: null,
      duration_minutes: null,
      start_time: null,
      group_role: null,
      department: null,
    };

    const updatedTaskStages = type.task_stages.map(
      (taskStage: any, index: any) => {
        if (index === taskStageIndex) {
          return {
            ...taskStage,
            task_templates: [...taskStage.task_templates, newTaskTemplate],
          };
        }
        return taskStage;
      }
    );

    setType({
      ...type,
      task_stages: updatedTaskStages,
    });
  };


  const handleRemoveTaskStage = (index: any) => {
    const updatedTaskStages = type.task_stages.filter(
      (_: any, i: any) => i !== index
    );
    setType({
      ...type,
      task_stages: updatedTaskStages,
    });
  };

  const handleRemoveTaskTemplate = (
    taskStageIndex: any,
    templateIndex: any
  ) => {
    const updatedTaskStages = type.task_stages.map((taskStage: any, i: any) => {
      if (i === taskStageIndex) {
        const updatedTaskTemplates = taskStage.task_templates.filter(
          (_: any, j: any) => j !== templateIndex
        );
        if (updatedTaskTemplates.length === 0) {
          setIsCreateTaskTemplate(false);
        }
        return {
          ...taskStage,
          task_templates: updatedTaskTemplates,
        };
      }
      return taskStage;
    });

    setType({
      ...type,
      task_stages: updatedTaskStages,
    });
  };

  const validateForm = () => {
    let isValid = true;
    let errors = [];
    if (!type.title) {
      errors.push(
        language === "en"
          ? "Project title is required."
          : "عنوان المشروع مطلوب."
      );
      isValid = false;
    }

    if (!type.description) {
      errors.push(
        language === "en"
          ? "Project description is required."
          : "وصف المشروع مطلوب."
      );
      isValid = false;
    }

    if (type.task_stages.length === 0) {
      errors.push(
        language === "en"
          ? "At least one task stage is required."
          : "مطلوب مرحلة واحدة على الأقل."
      );
      isValid = false;
    } else {
      type.task_stages.forEach((taskStage: any, taskStageIndex: any) => {
        if (!taskStage.title) {
          errors.push(
            `${language === "en"
              ? "Task stage title is required for task stage"
              : "عنوان مرحلة المهمة مطلوب لمرحلة المهمة"
            } ${taskStageIndex + 1}.`
          );
          isValid = false;
        }
        if (!taskStage.stage) {
          errors.push(
            `${language === "en"
              ? "Stage is required for task stage"
              : "المرحلة مطلوبة لمرحلة المهمة"
            } ${taskStageIndex + 1}.`
          );
          isValid = false;
        }
        if (taskStage.task_templates.length === 0) {
          errors.push(
            `${language === "en"
              ? "At least one task template is required for task stage"
              : "مطلوب قالب مهمة واحد على الأقل لمرحلة المهمة"
            } ${taskStageIndex + 1}.`
          );
          isValid = false;
        } else {
          if (taskStage.task_templates.length > 0) {
            taskStage.task_templates.forEach(
              (taskTemplate: any, templateIndex: any) => {
                if (!taskTemplate.title) {
                  errors.push(
                    `${language === "en"
                      ? "Task template title is required for task stage"
                      : "عنوان قالب المهمة مطلوب لمرحلة المهمة"
                    } ${taskStageIndex + 1}, ${language === "en" ? "template" : "القالب"
                    } ${templateIndex + 1}.`
                  );
                  isValid = false;
                } if (taskTemplate.assigned_group === "department" && !taskTemplate.department) {
                  errors.push(
                    `${language === "en"
                      ? "Department is required for task stage"
                      : "القسم مطلوب لمرحلة المهمة"
                    } ${taskStageIndex + 1}, ${language === "en" ? "template" : "القالب"
                    } ${templateIndex + 1}.`
                  );
                  isValid = false;
                }
                if (taskTemplate.group_role === null || taskTemplate.group_role === "") {
                  errors.push(
                    `${language === "en"
                      ? "Group Role is required for task stage"
                      : "دور المجموعة مطلوب لمرحلة المهمة"
                    } ${taskStageIndex + 1}, ${language === "en" ? "template" : "القالب"
                    } ${templateIndex + 1}.`
                  );
                  isValid = false;
                }
                if (taskTemplate.type === null || taskTemplate.type === "") {
                  errors.push(
                    `${language === "en"
                      ? "Type is required for task stage"
                      : "النوع مطلوب لمرحلة المهمة"
                    } ${taskStageIndex + 1}, ${language === "en" ? "template" : "القالب"
                    } ${templateIndex + 1}.`
                  );
                  isValid = false;
                }
              }
            );
          }
        }
      });
    }

    setFormError(errors.join("\n"));
    return isValid;
  };


  const setTaskForms = () => {
    return (
      <div className="flex flex-col my-6 p-2">
        <CardTitle className="text-xl font-bold text-center my-2">
          {language === "en" ? "Task Stages" : "مراحل المهمة"}
        </CardTitle>

        <>
          {type.task_stages.map((taskStage: any, index: any) => (
            <Card
              key={index}
              className="flex gap-2 flex-wrap justify-between my-4 p-2"
            >
              <div className="flex justify-end w-full">
                <Button
                  variant={"destructive"}
                  onClick={() => handleRemoveTaskStage(index)}
                  permission="change_projecttypes"
                >
                  <MdDeleteForever
                    className="hover:text-red-500"
                    size={20}
                    title="Delete Task Stage"
                  />
                </Button>
              </div>
              <div className="w-full">
                <CardTitle className="text-xl font-bold text-center my-2">
                  {setInputField(
                    language === "en" ? "Stage Number" : "رقم المرحلة",
                    "index",
                    taskStage?.index,
                    "taskStages",
                    "number",
                    index
                  )}
                </CardTitle>
              </div>
              <div className="w-full lg:w-2/5">
                {setInputField(
                  language === "en" ? "Task Stage Title" : "عنوان مرحلة المهمة",
                  "title",
                  taskStage.title,
                  "taskStages",
                  "text",
                  index
                )}
              </div>
              <div className="w-full lg:w-2/5">
                {setInputField(
                  language === "en" ? "Description" : "الوصف",
                  "description",
                  taskStage.description,
                  "taskStages",
                  "text",
                  index
                )}
              </div>
              <div className="w-full">
                {setSelectField(
                  language === "en" ? "Stage" : "المرحلة",
                  "stage",
                  taskStage?.stage?.id,
                  fetchedProjectStages,
                  "taskStages",
                  index,
                  null,
                  true,
                  "/projects/create-stage",
                  "add_stages"
                )}
              </div>

              {setTemplateForms(index)}
            </Card>
          ))}
          <div>
            <Button
              onClick={handleAddTaskStage}
              permission="change_projecttypes"
            >
              {language === "en" ? "Add Task Stage" : "إضافة مرحلة المهمة"}
            </Button>
          </div>
        </>
      </div>
    );
  };

  const setTemplateForms = (taskStageIndex: any) => {
    return (
      <div key={taskStageIndex} className="flex flex-col my-4 p-2 w-full">
        <CardTitle className="text-xl font-bold text-center my-2">
          {language === "en" ? "Task Templates" : "قوالب المهمة"}
        </CardTitle>
        <>
          <div className="flex flex-col">
            {type.task_stages[taskStageIndex].task_templates.map(
              (taskTemplate: any, templateIndex: any) => (
                <Card
                  key={templateIndex}
                  className="flex gap-2 flex-wrap justify-between  my-4 p-2"
                >
                  <div className="flex justify-end w-full">
                    <Button
                      variant={"destructive"}
                      onClick={() =>
                        handleRemoveTaskTemplate(taskStageIndex, templateIndex)
                      }
                      permission="change_projecttypes"
                    >
                      <MdDeleteForever
                        className="hover:text-red-500"
                        size={20}
                        title="Delete Task Template"
                      />
                    </Button>
                  </div>
                  <div className="w-full">
                    <CardTitle className="text-xl font-bold text-center my-2">
                      {setInputField(
                        language === "en" ? "Template Number" : "رقم القالب",
                        "index",
                        taskTemplate.index
                          ? taskTemplate.index
                          : templateIndex + 1,
                        "taskTemplates",
                        "number",
                        taskStageIndex,
                        templateIndex
                      )}
                    </CardTitle>
                  </div>
                  <div className="w-full lg:w-2/5">
                    {setInputField(
                      language === "en" ? "Title" : "العنوان",
                      "title",
                      taskTemplate.title,
                      "taskTemplates",
                      "text",
                      taskStageIndex,
                      templateIndex
                    )}
                  </div>
                  <div className="w-full lg:w-2/5">
                    {setInputField(
                      language === "en" ? "Description" : "الوصف",
                      "description",
                      taskTemplate.description,
                      "taskTemplates",
                      "text",
                      taskStageIndex,
                      templateIndex
                    )}
                  </div>
                  <div className="w-full lg:w-2/5">
                    {setSelectField(
                      language === "en" ? "Type" : "النوع",
                      "type",
                      taskTemplate.type,
                      taskTemplatesType,
                      "taskTemplates",
                      taskStageIndex,
                      templateIndex
                    )}
                    {taskTemplate.type === "form" && (
                      <div>
                        {isFetchingFormTemplates ? (
                          <LoadingIcon />
                        ) : (
                          <>
                            {setSelectField(
                              language === "en" ? "Form" : "النموذج",
                              "form_template",
                              taskTemplate?.form_template,
                              fetchedFormTemplates,
                              "taskTemplates",
                              taskStageIndex,
                              templateIndex,
                              true,
                              "/forms/create",
                              "add_forms"
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="w-full lg:w-2/5">
                    {setSelectField(
                      language === "en" ? "Assigned Group" : "المجموعة المعينة",
                      "assigned_group",
                      taskTemplate.assigned_group,
                      taskTemplatesAssignedGroup,
                      "taskTemplates",
                      taskStageIndex,
                      templateIndex
                    )}
                  </div>
                  {
                    taskTemplate.assigned_group === "department" &&
                    <>
                      <div className="w-full">
                        {isFetchingDepartments ? (
                          <LoadingIcon />
                        ) : (
                          setSelectField(
                            language === "en" ? "Department" : "القسم",
                            "department",
                            taskTemplate.department,
                            fetchedDepartments,
                            "taskTemplates",
                            taskStageIndex,
                            templateIndex,
                            true,
                            "/organization/create-department",
                            "add_departments"
                          )
                        )}
                      </div>

                      <div className="w-full">
                        {setSelectField(
                          language === "en"
                            ? "Department Group Role"
                            : "دور المجموعة في القسم",
                          "group_role",
                          taskTemplate.group_role,
                          taskTemplatesGroupRole,
                          "taskTemplates",
                          taskStageIndex,
                          templateIndex
                        )}
                      </div>

                      {
                        (taskTemplate.group_role === "department_role" && fetchedDepartments) &&
                        <div className="w-full">
                          {setSelectField(
                            language === "en"
                              ? `Position in Department ${taskTemplate?.department ? "" : "(Please select department first)"}`
                              : `المنصب في القسم ${taskTemplate?.department ? "" : "(يرجى تحديد القسم أولاً)"}`,
                            "department_role",
                            taskTemplate.department_role,
                            fetchedDepartments.find((department: any) => department.value === taskTemplate.department)?.roles || [],
                            "taskTemplates",
                            taskStageIndex,
                            templateIndex
                          )}
                        </div>
                      }
                    </>
                  }
                  <div className="w-full lg:w-2/5">
                    {setInputField(
                      language === "en" ? "Duration Minutes" : "مدة الدقائق",
                      "duration_minutes",
                      taskTemplate.duration_minutes,
                      "taskTemplates",
                      "number",
                      taskStageIndex,
                      templateIndex
                    )}
                  </div>
                  <div className="w-full lg:w-2/5">
                    {setInputField(
                      language === "en" ? "Start Time" : "وقت البدء",
                      "start_time",
                      taskTemplate.start_time,
                      "taskTemplates",
                      "time",
                      taskStageIndex,
                      templateIndex
                    )}
                  </div>
                  <div className="w-full">
                    {isFetchingDepartments ? (
                      <LoadingIcon />
                    ) : (
                      setMultiSelectField(
                        language === "en" ? "Dependent Task" : "مهمة مرتبطة",
                        "dependent_task",
                        taskTemplate?.dependent_task,
                        dependentTasksOptions(taskStageIndex, type.task_stages),
                        "taskTemplates",
                        taskStageIndex,
                        templateIndex
                      )
                    )}
                  </div>
                  <div className="flex flex-wrap gap-4 ">
                    <Label htmlFor={"view_project" + taskStageIndex}>
                      {language === "en"
                        ? "View project details in task?"
                        : "رؤية تفاصيل المشروع في المهمة؟"}
                    </Label>
                    <Checkbox
                      id={"view_project" + taskStageIndex}
                      checked={taskTemplate?.is_project_visible}
                      onCheckedChange={(value: any) => {
                        handleChange(
                          {
                            target: {
                              name: "is_project_visible",
                              value: value,
                            },
                          },
                          "taskTemplates",
                          taskStageIndex,
                          templateIndex
                        );
                      }}
                    />
                  </div>
                </Card>
              )
            )}
          </div>

          <div className="w-full flex justify-center">
            <Button
              onClick={() => {
                handleAddTaskTemplate(taskStageIndex);
              }}
              permission="change_projecttypes"
            >
              {language === "en" ? "Add New Template" : "إضافة قالب جديد"}
            </Button>
          </div>
        </>
      </div>
    );
  };
  const handleSave = () => {
    if (validateForm()) {
      setFormError("");
      projectTypes
        .update(id, type)
        .then(() => {
          navigate("/projects/settings", { replace: true });
        })
        .catch((err) => {
          console.log(err);
          setFormLoading(false);
        });
    }
  };

  const setInputField = (
    label: any,
    field: any,
    value: any,
    table: any,
    type = "text",
    taskStageIndex = null,
    templateIndex = null
  ) => {
    return (
      <div className="flex flex-col my-4">
        <Label className="mb-1">{label}</Label>
        <Input
          type={type}
          value={value}
          name={field}
          onChange={(e) =>
            handleChange(e, table, taskStageIndex, templateIndex)
          }
          disabled={!canEditProjectTypes}
        />
      </div>
    );
  };

  const setSelectField = (
    label: any,
    field: any,
    value: any,
    options: any,
    table: any,
    taskStageIndex = null,
    templateIndex = null,
    with_add = false,
    url = "",
    permission?: string
  ) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-1">{label}</Label>
        <div className="flex gap-2 w-full">
          <div className={`${with_add ? "w-4/5 " : "w-full"}`}>
            <Select
              onValueChange={(value) => {
                handleChange(
                  { target: { name: field, value: value } },
                  table,
                  taskStageIndex,
                  templateIndex
                )
              }
              }
              value={value}
              name={field}
              disabled={!canEditProjectTypes}
              dir={language === "en" ? "ltr" : "rtl"}
            >
              <SelectTrigger>
                <SelectValue
                  placeholder={
                    language === "en" ? `Select ${label}` : `اختر ${label}`
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>
                    {language === "en" ? `Select ${label}` : `اختر ${label}`}
                  </SelectLabel>
                  {options?.map((option: any, index: any) => (
                    <SelectItem
                      key={index} value={option?.value || option?.id}
                    >
                      {option?.display_name || option?.title}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          {with_add && (
            <div>
              <Button
                variant="outline"
                onClick={() => functionToCall(field || "")}
                permission={permission}
              >
                <FiRefreshCw />
              </Button>
            </div>
          )}
        </div>
        {with_add && (
          <Button
            variant="link"
            className="text-sm flex justify-start w-fit"
            onClick={() => window.open(url, "_blank")}
            permission={permission}
          >
            {language === "en" ? "Add New" : "إضافة جديد"}
          </Button>
        )}
      </div>
    );
  };

  const setMultiSelectField = (
    label: any,
    field: any,
    value: any,
    options: any,
    table: any,
    taskStageIndex = null,
    templateIndex = null
  ) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-1">{label}</Label>
        <MultiSelect
          defaultValue={value}
          options={options}
          onValueChange={(value) =>
            handleChange(
              { target: { name: field, value: value } },
              table,
              taskStageIndex,
              templateIndex
            )
          }
          animation={2}
          className="w-full"
          disabled={!canEditProjectTypes}
        />
      </div>
    );
  };
  const functionToCall = (field: string) => {
    if (field === "department") {
      fetchDepartments();
    } else if (field === "stage") {
      fetchStages();
    } else if (field === "form_template") {
      fetchFormTemplates();
    }
  };

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {(departmentsLoading || formLoading) && <LoadingScreen />}
      {fetchedProjectStages &&
        fetchedDepartments &&
        fetchedTaskTemplates &&
        fetchedFormTemplates &&
        type && (
          <Card className="max-w-3xl mx-auto my-8 ">
            <CardHeader>
              <Button
                onClick={() => navigate("/projects/settings")}
                className="w-full my-4"
                variant={"secondary"}
              >
                {language === "en" ? "Back" : "عودة"}
              </Button>
              <CardTitle className="text-xl font-bold">
                {language === "en" ? "Edit Project Type" : "تعديل نوع المشروع"}
              </CardTitle>
            </CardHeader>
            <CardContent className="text-start">
              <div className="flex flex-col gap-4">
                {setInputField(
                  language === "en" ? "Title" : "العنوان",
                  "title",
                  type.title,
                  "type"
                )}
                {setInputField(
                  language === "en" ? "Description" : "الوصف",
                  "description",
                  type.description,
                  "type"
                )}

                {setTaskForms()}

                {formError && (
                  <div>
                    {formError.split("\n").map((error: any) => (
                      <div className="text-red-500 text-sm my-2 font-bold ">
                        {error}
                      </div>
                    ))}
                  </div>
                )}
                <Button
                  onClick={handleSave} disabled={formLoading}
                  permission="change_projecttypes"
                >
                  {formLoading ? (
                    <LoadingIcon />
                  ) : language === "en" ? (
                    "Save"
                  ) : (
                    "حفظ"
                  )}
                </Button>
              </div>
            </CardContent>
          </Card>
        )}
    </div>
  );
};

export default EditProjectType;
