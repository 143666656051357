import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import { useEffect, useState } from "react";
import GoLogo from "@/assets/lazem-GO-logo.webp";
import { Input } from "@/components/ui/input";
import { cards, userFullNameSerializer } from "@/services/user-service";
import { Badge } from "@/components/ui/badge";

const CardsDashboards = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [filteredCards, setFilteredCards] = useState<any>();

  useEffect(() => {
    document.title = "Lazem GO - Cards Management";
  }, []);

  const {
    data: cardsData,
    isLoading: cardsLoading,
  } = useQuery({
    queryKey: ["cards management"],
    queryFn: () => cards.list().then((res:any) => {
      setFilteredCards(res?.data);
      return res?.data;
    }),
    refetchOnWindowFocus: false,
  });

  const handleSearch = (e: any) => {
    const value = e.target.value;
    const filtered = cardsData?.filter((card: any) => {
      return card?.user?.full_name?.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCards(filtered);
  }

  const handleArSearch = (e: any) => {
    const value = e.target.value;
    const filtered = cardsData?.filter((card: any) => {
      return card?.user?.full_name_ar?.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCards(filtered);
  }
  const cardBadge = (status: string) => {
    // status_list = ["need_update", "ready", "requested","printed","delivered","expired"]
    if (status == "need_update") {
      return (
        <Badge
          className="w-fit p-2"
          variant={"destructive"}
        >
          {language === "en" ? "Need Update" : "تحتاج تحديث"
          }
        </Badge>
      )
    }
    else if (status == "ready") {
      return (
        <Badge
          className="w-fit p-2"
          variant={"secondary"}
        >
          {language === "en" ? "Ready" : "جاهزة"
          }
        </Badge>
      )
    }
    else if (status == "requested") {
      return (
        <Badge
          className="w-fit p-2"
          variant={"secondary"}
        >
          {language === "en" ? "Requested" : "طلبت"
          }
        </Badge>
      )
    }
    else if (status == "printed") {
      return (
        <Badge
          className="w-fit p-2"
          variant={"outline"}
        >
          {language === "en" ? "Printed" : "مطبوعة"
          }
        </Badge>
      )
    }
    else if (status == "delivered") {
      return (
        <Badge
          className="w-fit p-2"
          variant={"default"}
        >
          {language === "en" ? "Delivered" : "تم التسليم"
          }
        </Badge>
      )
    }
    else if (status == "expired") {
      return (
        <Badge
          className="w-fit p-2"
          variant={"destructive"}
        >
          {language === "en" ? "Expired" : "منتهية"
          }
        </Badge>
      )
    }
  }

  return (
    <div dir={language === "en" ? "ltr" : "rtl"} className="p-4">
      {cardsLoading && <LoadingScreen />}
      {
        <div>
          <div className="my-6">
            <h1 className="text-2xl font-bold">
              {language === "en" ? "Cards Management" : "إدارة البطاقات"}
            </h1>

            <div className={`flex justify-start items-center gap-6 mt-4 mx-6`}>
              <Button
                onClick={() => navigate("/organization")}
                className="w-fit"
                variant={"secondary"}
              >
                {language === "en" ? "Back" : "العودة"}
              </Button>
            </div>
            <div
              className={`flex justify-between items-center gap-6 mt-4 mx-6 flex-wrap`}
            >
              <Input
                type="text"
                placeholder={language === "en" ? "Search by english name" : "البحث بالاسم الانجليزي"}
                onChange={handleSearch}
                className="w-[250px]"
              />
              <Input
                type="text"
                placeholder={language === "en" ? "Search by arabic name" : "البحث بالاسم العربي"}
                onChange={handleArSearch}
                className="w-[250px]"
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-4 justify-center">
            {filteredCards?.map((card: any) => {
              return (
                <Card
                  key={card?.id}
                  className={`w-[250px] h-[300px] flex flex-col justify-center`}
                >
                  <CardHeader>
                    {cardBadge(card?.status)}
                    <CardTitle className="text-lg font-bold capitalize">
                      {userFullNameSerializer(card?.user, language)}
                      <div
                        className={`flex justify-center items-center m-auto w-[100px] h-[100px] rounded-full`}
                        style={
                          {
                            backgroundImage: `url(${card?.photo || GoLogo})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }
                        }
                      >
                      </div>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p>{card?.role}</p>
                  </CardContent>
                  <CardFooter>
                    <Button
                      onClick={() =>
                        navigate(`/organization/cards/id/${card?.id}`)
                      }
                      className="w-full"
                    >
                      {language === "en" ? "View" : "عرض"}
                    </Button>
                  </CardFooter>
                </Card>
              );
            })}
          </div>
        </div>
      }
    </div>
  );
};

export default CardsDashboards;
