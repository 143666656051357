import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { userFullNameSerializer, users } from "@/services/user-service";
import LoadingScreen from "@/components/loading/loading-screen";
import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

const UsersManagement = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [view, setView] = useState("simple");

  useEffect(() => {
    document.title = "Lazem GO - Users Management";
  }, []);

  const {
    data: usersData,
    isLoading: usersLoading,
  } = useQuery({
    queryKey: ["users management"],
    queryFn: () => {
      return users
        .simple()
        .then((response:any) => {
          setFilteredUsers(response?.data);
          return response?.data;
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
    refetchOnWindowFocus: false,
    enabled: (view === "simple"),
  });
  const full_name_generator = (first_name?: string, middle_name?: string, last_name?: string) => {
    let full_name = first_name
    if (middle_name) {
      full_name += " " + middle_name
    }
    if (last_name) {
      full_name += " " + last_name
    }
    return full_name

  }
  const serializeUsersDetailed = (users: any) => {
    const result = users.map((user: any) => {
      return {
        id: user.id,
        full_name: full_name_generator(user?.first_name, user?.middle_name, user?.last_name),
        email: user?.email,
        created_at: user?.created_at,
        updated_at: user?.updated_at,
        is_active: user?.is_active,
        is_verified: user?.is_verified,
        groups: user?.groups,
        department: user?.staff_profile?.department?.title,
        position: user?.staff_profile?.role?.title,
        last_login: user?.last_login,
        is_superuser: user?.is_superuser,
        is_staff: user?.is_staff,
      };
    });
    return result;
  }

  const {
    data: usersDetailedData,
    isLoading: usersDetailedLoading,
  } = useQuery({
    queryKey: ["users detailed management"],
    queryFn: () => {
      return users
        .profiles()
        .then((response:any) => {
          setFilteredUsers(serializeUsersDetailed(response?.data));
          return response?.data;
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
    refetchOnWindowFocus: false,
    enabled: (view === "detailed"),
  });

  const handleUsersFilter = (name: string) => {
    const filtered = usersData?.filter((user: any) =>
      user?.full_name?.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredUsers(filtered || []);
  };


  const simpleView = () => {
    if (usersLoading) {
      return <LoadingScreen />;
    }
    return (<div className="flex flex-wrap gap-4 justify-center">
      {filteredUsers?.map((user: any) => (
        <Card key={user.id} className="w-64">
          <CardHeader className="min-h-32">
            <CardTitle className="text-lg font-bold capitalize">
              {userFullNameSerializer(user, language)}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p>{user.email}</p>
          </CardContent>
          <CardFooter>
            <Button
              onClick={() =>
                navigate(`/organization/users/id/${user.id}`)
              }
            >
              {language === "en" ? "View" : "عرض"}
            </Button>
          </CardFooter>
        </Card>
      ))}
    </div>)

  }
  const detailedView = () => {
    if (usersDetailedLoading) {
      return <LoadingScreen />
    }
    return (<div className="flex flex-wrap gap-4 justify-center">
      {filteredUsers?.map((user: any) => (
        <Card key={user?.id} className="w-64">
          <CardHeader className="min-h-32">
            <CardTitle className="text-lg font-bold capitalize">
              {user?.full_name}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p>Email: {user?.email}</p>
            <p>Department: {user?.department}</p>
            <p>Position: {user?.position}</p>
            <p>Is Active: {user?.is_active ? "Yes" : "No"}</p>
            <p>Is Verified: {user?.is_verified ? "Yes" : "No"}</p>
            <p>Permissions Group: {user?.groups}</p>
            <p>Last Login: {user?.last_login}</p>
            <p>Is Superuser: {user?.is_superuser ? "Yes" : "No"}</p>
            <p>Is Staff: {user?.is_staff ? "Yes" : "No"}</p>
          </CardContent>
          <CardFooter>
            <Button
              onClick={() =>
                navigate(`/organization/users/id/${user.id}`)
              }
            >
              {language === "en" ? "View" : "عرض"}
            </Button>
          </CardFooter>
        </Card>
      ))}
    </div>)
  }

  const handleView = () => {
    if (view === "detailed" && usersDetailedData) {
      return detailedView();
    } else if (view === "simple" && usersData) {
      return simpleView();
    }
    return <LoadingScreen />;
  };


  return (
    <div dir={language === "en" ? "ltr" : "rtl"} className="p-4">
      {
        <div>
          <div className="my-6">
            <h1 className="text-2xl font-bold">
              {language === "en" ? "Users Management" : "إدارة المستخدمين"}
            </h1>

            <div className="flex justify-start items-center gap-6 mt-4 mx-6">
              <Button
                onClick={() => navigate("/organization")}
                className="w-fit"
                variant={"secondary"}
              >
                {language === "en" ? "Back" : "العودة"}
              </Button>
            </div>
            <div
              className="flex justify-between items-center mt-4 mx-6 flex-wrap gap-4"
            >
              <Tabs
                onValueChange={(value) => setView(value)}
                className="mx-6 w-full md:w-2/5 lg:w-3/5"
                defaultValue="simple"

              >
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger value="simple">
                    {language === "en" ? "Simple View" : "عرض بسيط"}
                  </TabsTrigger>
                  <TabsTrigger
                    value="detailed"
                  >
                    {language === "en" ? "Detailed View" : "عرض مفصل"}
                  </TabsTrigger>
                </TabsList>
              </Tabs>
              <Input
                placeholder={
                  language === "en" ? "Search by name" : "البحث بالاسم"
                }
                onChange={(e) => handleUsersFilter(e.target.value)}
                className="w-full md:w-1/2 lg:w-1/3"
              />
            </div>
          </div>
          {handleView()}
        </div>
      }
    </div>
  );
};

export default UsersManagement;
