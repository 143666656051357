import { useEffect, useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { RadioGroupItem, RadioGroup } from "@/components/ui/radio-group";
import LoadingIcon from "@/components/loading/loading-icon";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardFooter,
    CardDescription,
} from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import { tickets, categories } from "@/services/tickets-services";

const TicketForm = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [search, setSearch] = useState(searchParams.get("request_type") || "");

    const [ticket, setTicket] = useState<any>({
        title: "",
        service_type: "",
        description: "A new ticket has been opened titled: ",
        priority: "",
        category: null,
        json_request: [
            {
                question_en: "Elaborate on the issue",
                question_ar: "تفصيل المشكلة",
                answer: "",
                name: "issue"
            },
        ],
    });
    const [serviceTypeList] = useState([
        {
            title_en: "Request a repair service",
            title_ar: "طلب خدمة إصلاح",
            value: "request_repair"
        },
        {
            title_en: "Report an issue",
            title_ar: "الإبلاغ عن مشكلة",
            value: "report_issue"
        },
        {
            title_en: "Request a new device",
            title_ar: "طلب جهاز جديد",
            value: "request_device"
        },
        {
            title_en: "Other",
            title_ar: "أخرى",
            value: "other"
        },
    ]);
    const [formError, setFormError] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "Lazem GO - Open Ticket";
        if (search) {
            setTicket({
                ...ticket,
                service_type: search,
                priority: "Medium",
            });
        }
    }, []);


    const checkRequiredFields = () => {
        if (ticket.title === "") {
            setFormError(language === 'en' ? "Title is required" : "العنوان مطلوب");
            window.scrollTo(0, 0);
            return false;
        }
        if (ticket.service_type === "") {
            setFormError(language === 'en' ? "Service Type is required" : "نوع الخدمة مطلوب");
            return false;
        }
        if (ticket.json_request[0].answer === "") {
            setFormError(language === 'en' ? "Elaborate on the issue is required" : "تفصيل المشكلة مطلوب");
            return false;
        }
        return true;
    }

    const {
        data: categoriesData,
        isLoading: categoriesLoading,
    } = useQuery({
        queryKey: ["categories open ticket"],
        queryFn: categories.list,
        refetchOnWindowFocus: false,
        enabled: !search,
    });
    const is_it_support = () => {
        return search === "technical_support";
    }

    const {
        data: categoryData,
        isLoading: categoryLoading,
    }:any = useQuery({
        queryKey: ["category open ticket"],
        queryFn: async () => categories.getWithFilter("tag=it_support"),
        refetchOnWindowFocus: false,
        enabled: is_it_support()
    });

    const {
        data: ticketsOptionData,
        isLoading: ticketsOptionLoading,
    } = useQuery({
        queryKey: ["tickets open ticket"],
        queryFn: tickets.options,
        refetchOnWindowFocus: false,
        enabled: !search,
    });

    const handleSubmit = () => {

        if (checkRequiredFields()) {
            setLoading(true);
            let new_description = ticket.description + ticket.title + " and the issue is: " + ticket.json_request[0].answer;
            let data_to_send = {
                title: ticket.title,
                service_type: ticket.service_type,
                description: new_description,
                priority: ticket.priority,
                category: ticket?.category || categoryData?.data[0]?.id,
                json_request: ticket.json_request
            };
            tickets.create(data_to_send).then((response:any) => {
                setLoading(false);
                navigate("/my-requests/id/" + response.data.id);
            }).catch((error) => {
                setFormError(error?.message);
                setLoading(false);
            });
        }
    }

    return (
        <div
            dir={language === 'en' ? 'ltr' : 'rtl'}
        >
            {(categoriesLoading || ticketsOptionLoading) && <LoadingScreen />}

            <Card
                className="w-11/12 m-auto mt-10 lg:w-1/2"
            >
                <CardHeader>
                    <Button
                        onClick={() => navigate("/my-requests")}
                        className="w-full my-4"
                        variant={"secondary"}
                    >
                        {language === 'en' ? "Back" : "عودة"}
                    </Button>
                    <CardTitle>
                        {language === 'en' ? "Open Request" : "فتح طلب جديد"}
                    </CardTitle>
                </CardHeader>
                <CardDescription>
                    {formError && (
                        <div className="text-red-500 text-center">
                            {formError}
                        </div>
                    )}
                </CardDescription>
                <CardContent
                    className="text-start"
                >
                    <div className="flex flex-col gap-6">
                        <div>
                            <Label>
                                {language === 'en' ? "Subject" : "الموضوع"}
                            </Label>
                            <Input
                                value={ticket.title}
                                onChange={(e) => setTicket({ ...ticket, title: e.target.value })}
                            />
                        </div>
                        {
                            !search &&
                            <>
                                <div>
                                    <Label>
                                        {language === 'en' ? "Service Type" : "نوع الخدمة"}
                                    </Label>
                                    <Select
                                        dir={language === 'en' ? 'ltr' : 'rtl'}
                                        value={ticket.service_type}
                                        onValueChange={(value) => setTicket({ ...ticket, service_type: value })}
                                    >
                                        <SelectTrigger>
                                            <SelectValue
                                                placeholder={language === 'en' ? "Select Service Type" : "اختر نوع الخدمة"}
                                            />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {serviceTypeList.map((service, index) => (
                                                <SelectGroup key={index}>
                                                    <SelectItem
                                                        value={service.value}
                                                        onClick={() => setTicket({ ...ticket, service_type: service.value })}
                                                    >
                                                        {language === 'en' ? service.title_en : service.title_ar}
                                                    </SelectItem>
                                                </SelectGroup>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                                <div>
                                    <Label>
                                        {language === 'en' ? "Category" : "الفئة"}
                                    </Label>
                                    <Select
                                        dir={language === 'en' ? 'ltr' : 'rtl'}
                                        onValueChange={(value) => setTicket({ ...ticket, category: value })}
                                    >
                                        <SelectTrigger>
                                            <SelectValue
                                                placeholder={language === 'en' ? "Select Category" : "اختر الفئة"}
                                            />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {categoriesData && categoriesData?.data?.map((category: any) => (
                                                <SelectGroup key={category?.id}>
                                                    <SelectItem
                                                        value={category?.id}
                                                    >
                                                        {category?.name}
                                                    </SelectItem>
                                                </SelectGroup>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                                <div>
                                    <Label>
                                        {language === 'en' ? "Priority" : "الأولوية"}
                                    </Label>
                                    <RadioGroup
                                        onValueChange={(value) => setTicket({ ...ticket, priority: value })}
                                        dir={language === 'en' ? 'ltr' : 'rtl'}
                                    >
                                        {
                                            ticketsOptionData && ticketsOptionData?.data?.actions?.POST?.priority?.choices?.map((priority: any) => (
                                                <div
                                                    key={priority?.value}
                                                    className="flex items-center gap-2"
                                                >
                                                    <RadioGroupItem
                                                        key={priority?.value}
                                                        id={priority?.value}
                                                        value={priority?.value}
                                                    />
                                                    <Label
                                                        htmlFor={priority?.value}
                                                    >
                                                        {priority?.display_name}
                                                    </Label>
                                                </div>
                                            ))
                                        }
                                    </RadioGroup>
                                </div>
                            </>
                        }
                        {ticket.json_request.map((question: any, index: any) => (
                            <div key={index} className="flex flex-col gap-2">
                                <Label>
                                    {language === 'en' ? question.question_en : question.question_ar}
                                </Label>
                                <Textarea
                                    value={question.answer}
                                    onChange={(e) => {
                                        let temp = ticket.json_request;
                                        temp[index].answer = e.target.value;
                                        setTicket({ ...ticket, json_request: temp });
                                    }
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </CardContent>
                <CardFooter>
                    <Button
                        onClick={handleSubmit}
                        disabled={loading}
                        className="w-full"
                    >
                        {loading ? <LoadingIcon /> : language === 'en' ? "Submit" : "إرسال"}
                    </Button>
                </CardFooter>
            </Card>
        </div>
    )
};

export default TicketForm;