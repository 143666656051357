import ApiUrl from "./api-url";
import axios, { isCancel } from 'axios';
import { ApiHeaderOptions } from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";

const stages = {
    list: async () => {
        const url = `${ApiUrl}/v1/projects/stages/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },

    create: async (data: any) => {
        const url = `${ApiUrl}/v1/projects/stages/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/projects/stages/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/projects/stages/${id}/`;
        return await axios.put(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    delete: async (id: any) => {
        const url = `${ApiUrl}/v1/projects/stages/${id}/`;
        return await axios.delete(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    }
}

const project_statuses = {
    list: async () => {
        const url = `${ApiUrl}/v1/projects/status/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },

    create: async (data: any) => {
        const url = `${ApiUrl}/v1/projects/status/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/projects/status/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/projects/status/${id}/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    delete: async (id: any) => {
        const url = `${ApiUrl}/v1/projects/status/${id}/`;
        return await axios.delete(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    }
}

const projects = {
    list: async () => {
        const url = `${ApiUrl}/v1/projects/projects/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },

    create: async (data: any) => {
        const url = `${ApiUrl}/v1/projects/projects/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/projects/projects/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/projects/projects/${id}/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error,"medium");
        });
    },
    list_project_managers: async () => {
        const url = `${ApiUrl}/v1/core/users/simple/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error,  "high");
        });
    },
    list_project_support_teams: async () => {
        const url = `${ApiUrl}/v1/core/users/simple/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
}

const projectTypes = {
    list: async () => {
        const url = `${ApiUrl}/v1/projects/types/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },

    create: async (data: any) => {
        const url = `${ApiUrl}/v1/projects/types/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/projects/types/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/projects/types/${id}/`;
        return await axios.put(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    options: async () => {
        const url = `${ApiUrl}/v1/projects/types/`;
        return await axios.options(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    list_task_templates: async () => {
        const url = `${ApiUrl}/v1/projects/task_templates/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    list_task_stages: async () => {
        const url = `${ApiUrl}/v1/projects/task_stages/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    }
}

const departments = {
    list: async () => {
        const url = `${ApiUrl}/v1/core/departments/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },

    create: async (data: any) => {
        const url = `${ApiUrl}/v1/core/departments/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/departments/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/core/departments/${id}/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    delete: async (id: any) => {
        const url = `${ApiUrl}/v1/core/departments/${id}/`;
        return await axios.delete(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error,"medium");
        });
    }
}
const comments = {
    list: async () => {
        const url = `${ApiUrl}/v1/projects/comments/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },

    create: async (data: any) => {

        const url = `${ApiUrl}/v1/projects/comments/`;
        return await axios.post(url, data, await ApiHeaderOptions(undefined)).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/projects/comments/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/projects/comments/${id}/`;
        return await axios.put(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error,  "medium");
        });
    }
}

export {
    departments,
    projectTypes,
    projects,
    comments,
    stages,
    project_statuses
};