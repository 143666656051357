import ApiUrl from "./api-url";
import axios, { isCancel } from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";

const PermissionGroups = {
    list: async () => {
        const url = `${ApiUrl}/v1/core/groups/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/groups/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "high");
        });
    },
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/core/groups/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "medium");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/core/groups/${id}/`;
        return await axios.put(url, data, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "medium");
        });
    },
    delete: async (id: any) => {
        const url = `${ApiUrl}/v1/core/groups/${id}/`;
        return await axios.delete(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "medium");
        });
    },
};

const Permissions = {
    list: async () => {
        const url = `${ApiUrl}/v1/core/permissions/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error,  "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/permissions/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "high");
        });
    },
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/core/permissions/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "medium");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/core/permissions/${id}/`;
        return await axios.put(url, data, await ApiHeaderOptions()).then((response) => {
            return response.data || [];
        }).catch((error) => {
            errorHandlingService(error, "medium");
        });
    },
};

export { PermissionGroups, Permissions };