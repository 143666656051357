import { useLanguage } from "@/components/context/LanguageContext"
import LoadingIcon from "@/components/loading/loading-icon"
import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { shortener } from "@/services/url-shortener-services"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const ShortenerLinksCreate = () => {

    const { language } = useLanguage()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [originalUrl, setOriginalUrl] = useState('')
    const [shortCode, setShortCode] = useState('')
    const [isActive, setIsActive] = useState('true')
    const [error, setError] = useState('')

    const createShortenerLink = async () => {
        if (checkIfFormIsFilled()) {
            return
        }
        setIsLoading(true)
        const data = {
            original_url: originalUrl,
            short_code: shortCode?.toLowerCase()?.replace(/ /g, '-'),
            is_active: isActive === 'true' ? true : false
        }
        await shortener.create(data).then((response:any) => {
            setIsLoading(false)
            navigate('/shortener-links/short-code/' + response?.data?.short_code)
        })
    }

    const checkIfFormIsFilled = () => {
        if (originalUrl === '' || shortCode === '') {
            setError(language === 'en' ? 'Please fill all the fields' : 'الرجاء ملء جميع الحقول')
            return true
        }
        return false
    }

    return (
        <div
            className="p-4"
            dir={language === 'en' ? 'ltr' : 'rtl'}
        >
            <Card
                className="text-start max-w-2xl mx-auto"
            >
                <CardHeader>
                    <Button
                        className="m-3 flex"
                        onClick={() => navigate('/shortener-links')}
                        variant={"secondary"}
                    >
                        {language === 'en' ? 'Back' : 'رجوع'}
                    </Button>
                    <CardTitle
                        className="text-center"
                    >
                        {language === 'en' ? 'Create Shortener Link' : 'إنشاء رابط مختصر'}
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="grid grid-cols-1 gap-4">
                        <Label>
                            {language === 'en' ? 'Original URL' : 'الرابط الأصلي'}
                        </Label>
                        <Input
                            value={originalUrl}
                            onChange={(e) => setOriginalUrl(e.target.value)}
                        />
                        <Label>
                            {language === 'en' ? 'Short URL' : 'الرابط المختصر'}
                        </Label>
                        <Input
                            value={shortCode}
                            onChange={(e) => setShortCode(e.target.value.replace(/ /g, '-'))}
                        />
                        <Label>
                            https://link.lazem.sa/{shortCode?.toLowerCase()?.replace(/ /g, '-')}
                        </Label>
                        <Label>
                            {language === 'en' ? 'Active' : 'نشط'}
                        </Label>
                        <RadioGroup
                            onValueChange={(value: any) => setIsActive(value)}
                            value={isActive}
                            className=""
                            dir={language === 'en' ? 'ltr' : 'rtl'}
                        >
                            <div
                                className="flex items-center gap-2"
                            >
                                <RadioGroupItem
                                    value={"true"}
                                    id="true"
                                />
                                <Label
                                    htmlFor="true"
                                >
                                    {language === 'en' ? 'Yes' : 'نعم'}
                                </Label>
                            </div>
                            <div
                                className="flex items-center gap-2"
                            >
                                <RadioGroupItem
                                    value={"false"}
                                    id="false"
                                />
                                <Label
                                    htmlFor="false"
                                >
                                    {language === 'en' ? 'No' : 'لا'}
                                </Label>
                            </div>
                        </RadioGroup>
                    </div>
                </CardContent>
                <CardFooter>
                    {error && <div className="text-red-500">{error}</div>}
                </CardFooter>
                <CardFooter>
                    <Button
                        onClick={createShortenerLink}
                        disabled={isLoading}
                        className="w-full"
                        permission="add_shortener"
                    >
                        {isLoading && <LoadingIcon />}
                        {language === 'en' ? 'Create' : 'إنشاء'}
                    </Button>
                </CardFooter>
            </Card>
        </div>
    )
}

export default ShortenerLinksCreate 
