import ApiUrl from "./api-url";
import axios from 'axios';
import { ApiHeaderOptions, getAuthorizationToken, getRefreshToken } from "./api-header-options";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { errorHandlingService } from "./error-handling-service";

const account = {
    register: async (data: any) => {
        const url = `${ApiUrl}/v1/core/users/`;
        return await axios.post(url, data).then((response) => {
            return response || [];
        }).catch((error: any) => {
        });
    },
    userSendActivation: async (email: any) => {
        const url = `${ApiUrl}/v1/auth/users/resend_activation/`;
        const frontEndUrl = process.env.FRONTEND_URL || "go.lazem.sa";
        let data = {
            "email": email,
            "website_name": "Lazem GO",
            "website_url": frontEndUrl + "/activate/?email=" + email + "&&",
        }
        return await axios.post(url, data).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    activateAccount: async (data: any) => {
        const url = `${ApiUrl}/v1/auth/users/activation/`;
        return await axios.post(url, data).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    userLogin: async (data: any) => {
        const url = `${ApiUrl}/v1/auth/jwt/create/`;
        return await axios.post(url, data).then((response) => {
            return response || [];
        }).catch((error: any) => {
            throw error;
        });
    },
    resetPassword: async (data: any) => {
        const url = `${ApiUrl}/v1/auth/users/reset_password/`;
        return await axios.post(url, data).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    submitNewPassword: async (data: any) => {
        const url = `${ApiUrl}/v1/auth/users/reset_password_confirm/`;
        return await axios.post(url, data).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    }
}


const getMyProfile = async () => {
    const url = `${ApiUrl}/v1/core/users/profile/`;
    return await axios.get(url, await ApiHeaderOptions()).then((response) => {
        return response || [];
    }).catch((error: any) => {
        errorHandlingService(error, "high")
    });
}


const updateProfile = async (data: any) => {
    const url = `${ApiUrl}/v1/core/profile/`;
    return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
        return response || [];
    }).catch((error: any) => {
        errorHandlingService(error, "medium")
    });
}


const handelSetCookie = async (data: any) => {
    let decoded = jwtDecode(data.access);
    Cookies.set('Authorization', "JWT " + data.access, {
        expires: new Date((decoded.exp || 0) * 1000)
    })
}

const users = {
    me: async () => {
        const url = `${ApiUrl}/v1/core/users/me/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    simple_me: async () => {
        const url = `${ApiUrl}/v1/core/users/simple_me/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    update_me: async (data: any) => {
        /*
        example_data = {
            "profile_user": {
                "phone_number": "05555555",
                "address": "Riyadh",
                "gender": "Male"
            },
            "staff_profile": {
                "department": "01fbb8d8-8ca6-4865-96d6-0fc52cfce224",
                "role": "ae3e28af-612e-490f-b565-45f07474d228"
            },
            "middle_name": "Bakheet",
            "last_name": "Bashah"
        }
        */
        const url = `${ApiUrl}/v1/core/users/me/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    update_profile_photo: async (data: any) => {
        const url = `${ApiUrl}/v1/core/staff_profiles/me/`;
        return await axios.patch(url, data, await ApiHeaderOptions(undefined)).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    list: async () => {
        const url = `${ApiUrl}/v1/core/users/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/users/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    profiles: async () => {
        const url = `${ApiUrl}/v1/core/users/profiles/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    simple: async () => {
        // Get simple user data [id, full_name, email]
        // This is useful for getting a list of users in a dropdown/select input field
        const url = `${ApiUrl}/v1/core/users/simple/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    update: async (id: any, data: any) => {
        /*
        example_data = {
            "profile_user": {
                "phone_number": "05555555",
                "address": "Riyadh",
                "gender": "Male"
            },
            "staff_profile": {
                "department": "01fbb8d8-8ca6-4865-96d6-0fc52cfce224",
                "role": "ae3e28af-612e-490f-b565-45f07474d228"
            },
            "middle_name": "Bakheet",
            "last_name": "Bashah"
        }
        */
        const url = `${ApiUrl}/v1/core/users/${id}/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    refreshToken: async () => {
        const url = `${ApiUrl}/v1/auth/jwt/refresh/`;
        let token = getRefreshToken();
        if (!token || token === '') {
            return false;
        }
        let refresh = token.split(' ')[1];
        let data = {
            "refresh": refresh
        }
        return await axios.post(url, data).then(async (response) => {
            await handelSetCookie(response.data);
            return response.data;
        }).catch(async (error: any) => {
            Cookies.remove('refresh');
            Cookies.remove('Authorization');
        });
    },
    verifyToken: async () => {
        const url = `${ApiUrl}/v1/auth/jwt/verify/`;
        return await axios.post(url, {
            token: getAuthorizationToken()
        }).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
            // Delete the token from the cookies
            Cookies.remove('Authorization');
        });
    }
}

const cards = {
    list: async () => {
        const url = `${ApiUrl}/v1/core/employee_card/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/employee_card/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    notify_employee: async (id: any) => {
        const url = `${ApiUrl}/v1/core/employee_card/notify_employee_to_update_profile/`;
        const data = {
            "id": id
        }
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    }
}
const business_cards = {
    list: async () => {
        const url = `${ApiUrl}/v1/core/business_card/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/business_card/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    notify_employee: async (id: any) => {
        const url = `${ApiUrl}/v1/core/business_card/notify_employee_to_update_profile/`;
        const data = {
            "id": id
        }
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    }
}

const card_logs = {
    create: async (data: any) => {
        /*
        data = {
            "card": "6ecfe547-018f-49a5-a2a0-e9e81a0fa285",
            "message": "Print request has been created",
            "new_status":"requested"
        }
        */
        const url = `${ApiUrl}/v1/core/employee_card_logs/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    }
}

const business_card_logs = {
    create: async (data: any) => {
        /*
        data = {
            "card": "6ecfe547-018f-49a5-a2a0-e9e81a0fa285",
            "message": "Print request has been created",
            "new_status":"requested"
        }
        */
        const url = `${ApiUrl}/v1/core/business_card_logs/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    }
}

const roles = {
    list: async () => {
        const url = `${ApiUrl}/v1/core/roles/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/roles/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "high");
        });
    },
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/core/roles/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/core/roles/${id}/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
}
const userFullNameSerializer = (user: any, language: any) => {
    if (language == "ar" && user?.full_name_ar) {
        return user?.full_name_ar;
    }
    return user?.full_name;
}
const staffCertificates = {
    post: async (data: any) => {
        // const data_example: FormData = new FormData();
        // data_example.append('file', file);
        // data_example.append('title', title);
        // data_example.append('description', description);
        // data_example.append('staff_profile', staff_profile_id);
        const url = `${ApiUrl}/v1/core/staff_certificates/`;
        return await axios.post(url, data, await ApiHeaderOptions(undefined)).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    deactivate: async (id: any) => {
        const url = `${ApiUrl}/v1/core/staff_certificates/${id}/`;
        return await axios.patch(url, {is_active:false}, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    },
    activate: async (id: any) => {
        const url = `${ApiUrl}/v1/core/staff_certificates/${id}/`;
        return await axios.patch(url, {is_active:true}, await ApiHeaderOptions()).then((response) => {
            return response || [];
        }).catch((error: any) => {
            errorHandlingService(error, "medium");
        });
    }
}


export { getMyProfile, updateProfile, users, roles, cards, account, card_logs, business_cards, business_card_logs, userFullNameSerializer, staffCertificates };