// TaskTemplateForm.tsx
import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import InputField from "./InputField";
import SelectField from "./SelectField";
import { Button } from "@/components/ui/button";
import { MdDeleteForever } from "react-icons/md";
import { useLanguage } from "@/components/context/LanguageContext";
import LoadingIcon from "@/components/loading/loading-icon";
import { Label } from "../ui/label";
import CheckboxField from "./checkboxField";

interface TaskTemplateFormProps {
    taskTemplate: any;
    taskStageIndex: number;
    templateIndex: number;
    onChange: (e: any, table: string, taskStageIndex?: number | null, templateIndex?: number | null) => void;
    onRemove: (taskStageIndex: number, templateIndex: number) => void;
    fetchedDepartments: any[];
    taskTemplatesType: any[];
    taskTemplatesAssignedGroup: any[];
    taskTemplatesGroupRole: any[];
    fetchedFormTemplates: any[];
    isFetchingFormTemplates: boolean;
    isFetchingDepartments: boolean;
    functionToCall: (field: string) => void;
    isEditing?: boolean;
}

const TaskTemplateForm: React.FC<TaskTemplateFormProps> = ({
    taskTemplate,
    taskStageIndex,
    templateIndex,
    onChange,
    onRemove,
    fetchedDepartments,
    taskTemplatesType,
    taskTemplatesAssignedGroup,
    taskTemplatesGroupRole,
    fetchedFormTemplates,
    isFetchingFormTemplates,
    isFetchingDepartments,
    functionToCall,
    isEditing = false,
}) => {
    const { language } = useLanguage();

    const dependentTasksOptions = (taskStageIndex: any, tasks: any) => {
        let options: any = [];
        for (let i = 0; i < taskStageIndex; i++) {
            tasks[i].task_templates.map((template: any) => {
                options.push({
                    value: template.id,
                    label: template.title,
                });
            });
        }
        return options;
    };
    return (
        <Card key={templateIndex} className="flex gap-2 flex-wrap justify-between  my-4 p-2">
            <div className="flex justify-end w-full">
                <Button
                    variant={"destructive"}
                    onClick={() => onRemove(taskStageIndex, templateIndex)}
                >
                    <MdDeleteForever
                        className="hover:text-red-500"
                        size={20}
                        title="Delete Task Template"
                    />
                </Button>
            </div>
            <div className="w-full">
                <CardTitle className="text-xl font-bold text-center my-2">
                    {language === "en" ? "Template Number" : "رقم القالب"} {templateIndex + 1}
                </CardTitle>
            </div>
            <div className="w-full lg:w-2/5">
                <InputField
                    label={language === "en" ? "Title" : "العنوان"}
                    field="title"
                    value={taskTemplate.title}
                    table="taskTemplates"
                    taskStageIndex={taskStageIndex}
                    templateIndex={templateIndex}
                    onChange={onChange}
                />
            </div>
            <div className="w-full lg:w-2/5">
                <InputField
                    label={language === "en" ? "Description" : "الوصف"}
                    field="description"
                    value={taskTemplate.description}
                    table="taskTemplates"
                    taskStageIndex={taskStageIndex}
                    templateIndex={templateIndex}
                    onChange={onChange}
                />
            </div>
            <div className="w-full lg:w-2/5">
                <SelectField
                    label={language === "en" ? "Type" : "النوع"}
                    field="type"
                    value={taskTemplate.type}
                    options={taskTemplatesType}
                    table="taskTemplates"
                    taskStageIndex={taskStageIndex}
                    templateIndex={templateIndex}
                    onChange={onChange}
                />
                {taskTemplate.type === "form" && (
                    <div>
                        {isFetchingFormTemplates ? (
                            <LoadingIcon />
                        ) : (
                            <SelectField
                                label={language === "en" ? "Form" : "النموذج"}
                                field="form_template"
                                value={taskTemplate.form}
                                options={fetchedFormTemplates}
                                table="taskTemplates"
                                taskStageIndex={taskStageIndex}
                                templateIndex={templateIndex}
                                onChange={onChange}
                                withAdd={true}
                                url="/forms/create"
                                functionToCall={functionToCall}
                                isLoadingOptions={isFetchingFormTemplates}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className="w-full lg:w-2/5">
                <SelectField
                    label={language === "en" ? "Assigned Group" : "المجموعة المعينة"}
                    field="assigned_group"
                    value={taskTemplate.assigned_group}
                    options={taskTemplatesAssignedGroup}
                    table="taskTemplates"
                    taskStageIndex={taskStageIndex}
                    templateIndex={templateIndex}
                    onChange={onChange}
                />
            </div>
            {taskTemplate.assigned_group === "department" && (
                <>
                    <div className="w-full">
                        {isFetchingDepartments ? (
                            <LoadingIcon />
                        ) : (
                            <SelectField
                                label={language === "en" ? "Department" : "القسم"}
                                field="department"
                                value={taskTemplate.department}
                                options={fetchedDepartments}
                                table="taskTemplates"
                                taskStageIndex={taskStageIndex}
                                templateIndex={templateIndex}
                                onChange={onChange}
                                withAdd={true}
                                url="/organization/create-department"
                                functionToCall={functionToCall}
                                isLoadingOptions={isFetchingDepartments}
                            />
                        )}
                    </div>

                    {taskTemplate.group_role === "department_role" && fetchedDepartments && (
                        <div className="w-full">
                            <SelectField
                                label={language === "en"
                                    ? `Position in Department ${taskTemplate?.department ? "" : "(Please select department first)"}`
                                    : `المنصب في القسم ${taskTemplate?.department ? "" : "(يرجى تحديد القسم أولاً)"}`}
                                field="department_role"
                                value={taskTemplate.department_role}
                                options={fetchedDepartments.find((department: any) => department.value === taskTemplate.department)?.roles || []}
                                table="taskTemplates"
                                taskStageIndex={taskStageIndex}
                                templateIndex={templateIndex}
                                onChange={onChange}
                            />
                        </div>
                    )}

                </>
            )}
            <div className="w-full">
                <SelectField
                    label={language === "en" ? "Department Group Role" : "دور المجموعة في القسم"}
                    field="group_role"
                    value={taskTemplate.group_role}
                    options={taskTemplatesGroupRole}
                    table="taskTemplates"
                    taskStageIndex={taskStageIndex}
                    templateIndex={templateIndex}
                    onChange={onChange}
                />
            </div>
            <div className="w-full lg:w-2/5">
                <InputField
                    label={language === "en" ? "Duration Minutes" : "مدة الدقائق"}
                    field="duration_minutes"
                    value={taskTemplate.duration_minutes}
                    table="taskTemplates"
                    type="number"
                    taskStageIndex={taskStageIndex}
                    templateIndex={templateIndex}
                    onChange={onChange}
                />
            </div>
            <div className="w-full lg:w-2/5">
                <InputField
                    label={language === "en" ? "Start Time" : "وقت البدء"}
                    field="start_time"
                    value={taskTemplate.start_time}
                    table="taskTemplates"
                    type="time"
                    taskStageIndex={taskStageIndex}
                    templateIndex={templateIndex}
                    onChange={onChange}
                />
            </div>
            {/* <div className="w-full">
                {isFetchingDepartments ? (
                    <LoadingIcon />
                ) : (
                    <SelectField
                        label={language === "en" ? "Dependent Task" : "مهمة مرتبطة"}
                        field="dependent_task"
                        value= {taskTemplate?.dependent_task}
                        options = {dependentTasksOptions(taskStageIndex, type.task_stages)}
                        table={"taskTemplates"}
                        taskStageIndex={taskStageIndex}
                        templateIndex={templateIndex}
                    />
                )}
            </div> */}
            {/* <div className="flex flex-wrap gap-4 ">
                <CheckboxField
                    label={language === "en" ? "View project details in task?" : "هل تريد عرض تفاصيل المشروع في المهمة؟"}
                    field="is_project_visible"
                    value={taskTemplate.view_project_details}
                    defaultCheck={taskTemplate.view_project_details}
                    table="taskTemplates"
                    taskStageIndex={taskStageIndex}
                    templateIndex={templateIndex}
                    onChange={onChange}
                />
            </div> */}
        </Card>
    );
};

export default TaskTemplateForm;