// TaskTemplates.tsx
import React, { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import TaskTemplateForm from "./TaskTemplateForm";
import { Button } from "@/components/ui/button";
import { useLanguage } from "@/components/context/LanguageContext";

interface TaskTemplatesProps {
  taskStages: any[];
  taskStageIndex: number;
  onChange: (e: any, table: string, taskStageIndex?: number | null, templateIndex?: number | null) => void;
  onRemoveTaskTemplate: (taskStageIndex: number, templateIndex: number) => void;
  onAddTaskTemplate: (taskStageIndex: number) => void;
  fetchedTaskTemplates: any[];
  fetchedDepartments: any[];
  taskTemplatesType: any[];
  taskTemplatesAssignedGroup: any[];
  taskTemplatesGroupRole: any[];
  fetchedFormTemplates: any[];
  isFetchingFormTemplates: boolean;
  isFetchingDepartments: boolean;
  functionToCall: (field: string) => void;
}

const TaskTemplates: React.FC<TaskTemplatesProps> = ({
  taskStages,
  taskStageIndex,
  onChange,
  onRemoveTaskTemplate,
  onAddTaskTemplate,
  fetchedTaskTemplates,
  fetchedDepartments,
  taskTemplatesType,
  taskTemplatesAssignedGroup,
  taskTemplatesGroupRole,
  fetchedFormTemplates,
  isFetchingFormTemplates,
  isFetchingDepartments,
  functionToCall,
}) => {
  const { language } = useLanguage();
  const [isCreateTaskTemplate, setIsCreateTaskTemplate] = useState(false);

  return (
    <div key={taskStageIndex} className="flex flex-col my-4 p-2 w-full">
      <CardTitle className="text-xl font-bold text-center my-2">
        {language === "en" ? "Task Templates" : "قوالب المهمة"}
      </CardTitle>
      {fetchedTaskTemplates.length === 0 || isCreateTaskTemplate ? (
        <>
          <div className="flex flex-col">
            {taskStages[taskStageIndex].task_templates.map(
              (taskTemplate: any, templateIndex: number) => (
                <TaskTemplateForm
                  key={templateIndex}
                  taskTemplate={taskTemplate}
                  taskStageIndex={taskStageIndex}
                  templateIndex={templateIndex}
                  onChange={onChange}
                  onRemove={onRemoveTaskTemplate}
                  fetchedDepartments={fetchedDepartments}
                  taskTemplatesType={taskTemplatesType}
                  taskTemplatesAssignedGroup={taskTemplatesAssignedGroup}
                  taskTemplatesGroupRole={taskTemplatesGroupRole}
                  fetchedFormTemplates={fetchedFormTemplates}
                  isFetchingFormTemplates={isFetchingFormTemplates}
                  isFetchingDepartments={isFetchingDepartments}
                  functionToCall={functionToCall}
                />
              )
            )}
          </div>

          <div className="w-full flex justify-center">
            <Button
              onClick={() => {
                onAddTaskTemplate(taskStageIndex);
              }}
            >
              {language === "en" ? "Add New Template" : "إضافة قالب جديد"}
            </Button>
          </div>
        </>
      ) : (
        <Card className="flex flex-col gap-4 p-2">
          <div className="flex justify-center">
            <Button
              onClick={() => {
                setIsCreateTaskTemplate(true);
                onAddTaskTemplate(taskStageIndex);
              }}
            >
              {language === "en"
                ? "Create New Task Template"
                : "إنشاء قالب مهمة جديد"}
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

export default TaskTemplates;